import React from 'react';
import { Link } from 'react-router-dom';
import './opening.css'; // Separate CSS file for styles


const Opening = () => {
  return (
    <div style={{
          backgroundImage: `linear-gradient(195deg, rgba(66, 66, 74, 0.6), rgba(25, 25, 25, 0.6)), url('bg.jpeg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          height: '100vh',
        }}>
      <div className="landing-page" >
        <div className="content2">
          <h1>
            Top-Down Cyber Risk <br /> <span>Quantification.</span>
          </h1>
          <p>
              Empowers Underwriters, Insurers, CISOs, CFOs and other senior executives to quantify and  
            <span> manage cyber risk at a strategic level .</span>
          </p>
          <div className="buttons">
            <Link to={'/SignIn'}>
              <button className="btn-primary">SIGN IN</button>
            </Link>
            <Link to={'/SignUp'}>
              <button className="btn-secondary">SIGN UP</button>
          </Link>  
          </div>
        </div>
      </div>
      <p className='copyright sign'>© Copyright, 2024 Assentian. All Rights Reserved</p>
    </div>
  );
};

export default Opening;
