import React, { useState, useEffect } from 'react';
import './Gloss.css'
import Copyright from '../CopyrightFooter/Copyright';

const GlossaryComp = () => {
  const [glossaryData, setGlossaryData] = useState([]);
  const [filterLetter, setFilterLetter] = useState(null);
  const [originalGlossaryData, setOriginalGlossaryData] = useState([]);
 //Fetches the glossary data from the 
  useEffect(() => {
    fetch('/glossary-export.json')
      .then(response => response.json())
      .then(data => {
        console.log('Fetched data:', data);
        setGlossaryData(data.parentTerms);
        setOriginalGlossaryData(data.parentTerms);
      })
      .catch(error => console.error('Error fetching glossary data:', error));
  }, []);
  // Handles the filtering of letters depending on the selection
  const handleFilter = letter => {
    setFilterLetter(letter);
  };

  
  //Handles the filtering for any terms that don't start with a letter 
  const handleFilterBeforeA = () => {
    const firstATermIndex = originalGlossaryData.findIndex(term =>
      term.term.toLowerCase().startsWith('a')
    );

    if (firstATermIndex !== -1) {
      const filteredTerms = originalGlossaryData.slice(0, firstATermIndex);
      setFilterLetter(null);
      setGlossaryData(filteredTerms);
    }
  };
 // This sets the glossary to its full set so the user can view all of the data
  const handleFilterAll = () => {
    setFilterLetter(null);
    setGlossaryData(originalGlossaryData);
  };

  const filteredGlossaryData = filterLetter
    ? originalGlossaryData.filter(term =>
        term.term.toLowerCase().startsWith(filterLetter.toLowerCase())
      )
    : glossaryData;

  
  // HTML for the frontend 
  return (
    <div style={{backgroundColor: 'rgb(240, 242, 245)'}} >
      <div class="glossarycontent"> 
        <div class="GlossContainerBox">
        <div className="Glossheader">
        <h1>Security Glossary</h1>
        <div className="buttonRow">
          <button className='letterbutton' onClick={handleFilterBeforeA}>Before A</button>
            {/* First row of letters (A-M) */}
            {Array.from({ length: 13 }, (_, index) => (
              <button className='letterbutton' key={index} onClick={() => handleFilter(String.fromCharCode(65 + index))}>
                {String.fromCharCode(65 + index)}
              </button>
            ))}
          </div>
          <div className="buttonRow">
            {/* Second row of letters (N-Z) */}
            {Array.from({ length: 13 }, (_, index) => (
              <button className='letterbutton' key={index + 13} onClick={() => handleFilter(String.fromCharCode(78 + index))}>
                {String.fromCharCode(78 + index)}
              </button>
            ))}<button className='letterbutton' onClick={handleFilterAll}>All</button>
          </div>
        </div>
          <div className="glossaryTable">
            <table>
              <thead>
                <tr>
                  <th>Term</th>
                  <th>Definition</th>
                  <th>Source</th>
                  <th>Link</th>
                </tr>
              </thead>
              <tbody>
                {filteredGlossaryData.map(term => (
                  <tr key={term.id}>
                    <td>{term.term}</td>
                    <td>{term.definitions ? (term.definitions[0].text || 'Not available') : 'Not available'}</td>
                    <td>{term.definitions && term.definitions[0].sources ? (term.definitions[0].sources[0].text || 'Not available') : 'Not available'}</td>
                    <td>
                      {term.definitions && term.definitions[0].sources && term.definitions[0].sources[0].link ? (
                        <a className='SourceLink' href={term.definitions[0].sources[0].link} target="_blank" rel="noopener noreferrer">
                          {term.definitions[0].sources[0].link || 'Not available'}
                        </a>
                      ) : 'Not available'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Copyright/>
    </div>

  );
};

export default GlossaryComp;
