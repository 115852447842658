import React, { useState, useEffect } from 'react';
import '../dash/dash.css'
import { MdStore } from "react-icons/md";
import { FaSquare, FaPiggyBank, FaUserClock, FaCoins } from "react-icons/fa";
import {  RiSwordFill  } from "react-icons/ri";
import { IoPersonAdd } from "react-icons/io5";
import { db, auth } from '../Firebase_Config/firebase';
import { collection, query, where, orderBy, limit, getDocs, doc, getDoc } from 'firebase/firestore';
import { Line, Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // This automatically registers all the components from Chart.js
import pattern from 'patternomaly'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { AiFillSecurityScan } from "react-icons/ai";
import { BsShieldFillExclamation } from "react-icons/bs";
import { GiSwordsEmblem, GiPayMoney, GiCash } from "react-icons/gi";
import { SiCyberdefenders } from "react-icons/si";
import { LuCalendarClock } from "react-icons/lu";
import { FaMoneyBillWave } from "react-icons/fa";
import { MdOutlineSecurity } from "react-icons/md";
import Copyright from '../CopyrightFooter/Copyright';



const NewAdminDashboard = () => {

  const [sixteenQuestRisk, setSixteenQuestRisk] = useState(0);
  const [sectorScoreCal, setsectorScoreCal] = useState(0);
  const [sectorName, setSectorName] = useState('');
  const [employeeScoreCal, setemployeeScoreCal] = useState(0);
  const [cyberRiskScore, setCyberRiskScore] = useState([]);
  const [turnover, setturnover] = useState([]);
  const [adminTrue, setAdminTrue] = useState(null);
  const [userOraganName, setUserOraganName] = useState('');
  const [userAnswers, setUserAnswers] = useState([]);




  useEffect(() => {
    const fetchSectorNameAndScores = async () => {
        try {
            const userId = getUserId();
            console.log('User ID:', userId); // Add this line for debugging

            // Fetch risk score
            const userDocQuery = query(collection(db, 'users'), where('userId', '==', userId));
            const userDocSnapshot = await getDocs(userDocQuery);

            if (!userDocSnapshot.empty) {
                const userDocRef = userDocSnapshot.docs[0].ref;
                const userDocData = userDocSnapshot.docs[0].data();
                const sectorScore =userDocData.Sector_Score ||0;
                const employeeScore =userDocData.Employee_Score ||0;
                const getSectorName = userDocData.sector || null;
                const getturnover = userDocData.turnover || null;
                const getOraganName = userDocData.OrganName|| null;
                setemployeeScoreCal(employeeScore)
                setsectorScoreCal(sectorScore)
                setSectorName(getSectorName)
                setturnover(getturnover)
                setUserOraganName(getOraganName)

                console.log(sectorName)
                
            } else {
                console.log('User document not found for user ID:', userId);
            }
            const answersDocRef = doc(db, 'Answers', userId); // Accessing the document by userId

              try {
                  const answersSnapshot = await getDoc(answersDocRef); // getDoc instead of getDocs since we're accessing a single document

                  if (answersSnapshot.exists()) {
                      const data = answersSnapshot.data();
                      console.log('answers Data:', data); // Add this line for debugging
                      
                      const CyberRiskScore = data.CyberRiskScore;
                      const getRiskScore = data.Risk_Score;
                      setCyberRiskScore(CyberRiskScore.toFixed(1));
                      setSixteenQuestRisk(getRiskScore)
                      setUserAnswers(data.Sixteen_Answers)
                      console.log('Risk Score:', getRiskScore); // Add this line for debugging
                  } else {
                      console.log('No such document!');
                  }
              } catch (error) {
                  console.error('Error fetching document:', error);
              }

          
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const checkAdminPrivileges = async () => {
      const userAdmin = auth.currentUser.uid; 
      try {     
          const userDocQuery = query(collection(db, 'users'), where('userId', '==', userAdmin));
          const userDocSnapshot = await getDocs(userDocQuery);

          if (!userDocSnapshot.empty) {
              const userDocData = userDocSnapshot.docs[0].data();
              const isAdminTrue = userDocData.isAdmin || null;
              setAdminTrue(isAdminTrue);
          } else {
              console.log('User document not found for user ID:', userAdmin);
          }


      } catch (error) {
          console.error('Error checking admin privileges:', error);
      }
  };

    checkAdminPrivileges();
    fetchSectorNameAndScores();
  }, []);

  const getUserId = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const userId = searchParams.get('id');
    return userId;
  };



  const calculateAverageFinancialImpactbySector = (sectorName) => {
    let sectorMediumScore = 0;
    switch (sectorName) {
        case "Manufacturing":
            sectorMediumScore = 1.2 ;
            break;
        case "Finance and insurance":
            sectorMediumScore = 2.5 ;
            break;
        case "Professional, business, and consumer services":
            sectorMediumScore = 1.1 ;
            break;
      case "Energy":
            sectorMediumScore = 2;
            break;
        case "Retail and wholesale":
            sectorMediumScore = 1.3;
            break;
        case "Education":
            sectorMediumScore = 1;
            break;
        case "Healthcare":
            sectorMediumScore = 1.5;
            break;
        case "Government":
            sectorMediumScore = 1.7;
            break;
        case "Transportation":
            sectorMediumScore = 1.4;
            break;
        case "Media and telecom":
            sectorMediumScore = 37707;
            break;
        case "Other":
            sectorMediumScore = 37707;
            break;
        default:
            sectorMediumScore = 37707;
            break;
    }
    return sectorMediumScore;
  };
  const AverageFinancialImpactbySector  = calculateAverageFinancialImpactbySector(sectorName)

  const getAdjustedFinancialImpact = (cyberRiskScore, sectorMediumScore) => {
    let adjustmentFactor = 0;
    if (cyberRiskScore <= 20) {
        adjustmentFactor = 0.5;
    } else if (cyberRiskScore <= 30) {
        adjustmentFactor = 0.6;
    } else if (cyberRiskScore <= 40) {
        adjustmentFactor = 0.7;
    } else if (cyberRiskScore <= 50) {
        adjustmentFactor = 0.75;
    } else if (cyberRiskScore <= 60) {
        adjustmentFactor = 0.8;
    } else if (cyberRiskScore <= 70) {
        adjustmentFactor = 0.85;
    } else if (cyberRiskScore <= 80) {
        adjustmentFactor = 0.9;
    } else {
        adjustmentFactor = 0.98;
    }

    return sectorMediumScore * adjustmentFactor;
  };
  const AdjustedFinancialImpact = getAdjustedFinancialImpact(cyberRiskScore, AverageFinancialImpactbySector)

  const calculateImpactOnRevenue = (AdjustedFinancialImpact, turnover) =>{
    const x = AdjustedFinancialImpact * Math.pow(10,6)
    const y = x / turnover
    const z = y *100
    return y.toFixed(1)
  }
  const ImpactOnRevenue= calculateImpactOnRevenue(AdjustedFinancialImpact, turnover)

  const calculateTotalRiskScore=(sixteenQuestRisk, cyberriskscore) => {
    // Convert the values to numbers
    const numericRiskScore = parseFloat(sixteenQuestRisk);
    const numericcyberriskscore = parseFloat(cyberriskscore);
    
    // Check if both values are valid numbers
    if (!isNaN(numericRiskScore) && !isNaN(numericcyberriskscore)) {
        // Calculate the average of the two scores
        const totalRiskScore = (numericRiskScore + numericcyberriskscore) / 2;
        console.log('risk',totalRiskScore)
        return totalRiskScore.toFixed(2); // Return the result rounded to 2 decimal places
    } else {
        return null; // Return null if either value is not a valid number
    }
  }
  const calculateLikelihoodScore = () => {
      const X = sectorScoreCal + employeeScoreCal;
      console.log('X:', X);
      const Y = X / 2;
      console.log('Y:', Y);
      console.log(totalRiskScore)
      const  XX = Y + parseFloat(totalRiskScore);
      console.log('XX:', XX);
      const YY = XX / 2;
      console.log('YY:', YY);
      let likelihoodScore = YY;

      if (totalRiskScore > 50) {
          likelihoodScore += 5;
      }

      console.log('Likelihood of Attack in next 12 months score:', likelihoodScore);

      return likelihoodScore;
  };

  const totalRiskScore = calculateTotalRiskScore(sixteenQuestRisk, cyberRiskScore);
  const likelihoodOfAttackScore = calculateLikelihoodScore();

  const getApproximateAverageCost = (sectorName) => {
    let costRange = { min: 0, max: 0 };

    switch (sectorName) {
        case "Manufacturing":
            costRange = { min: 500000, max: 1500000, text:'Downtime in production, loss of intellectual property, and recovery costs.' };
            break;
        case "Finance and insurance":
            costRange = { min: 700000, max: 2000000, text:'Regulatory fines, loss of customer trust, and theft of financial data.' };
            break;
        case "Professional, business, and consumer services":
            costRange = { min: 450000, max: 1300000, text:'Business email compromise, data breaches, and loss of sensitive client information.' };
            break;
        case "Energy":
            costRange = { min: 800000, max: 2500000, text:'Disruption of operations, ransomware attacks on critical infrastructure, and potential environmental hazards.' };
            break;
        case "Retail and wholesale":
            costRange = { min: 400000, max: 1200000, text:'Theft of payment card data, loss of customer trust, and regulatory fines.' };
            break;
        case "Education":
            costRange = { min: 300000, max: 1000000, text:'Theft of personal information of students and staff, disruption of educational services, and ransomware.' };
            break;
        case "Healthcare":
            costRange = { min: 600000, max: 1800000, text:'Theft of personal health information, disruption of medical services, and regulatory fines.' };
            break;
        case "Government":
            costRange = { min: 500000, max: 1500000, text:'Theft of sensitive data, disruption of public services, and potential national security risks.'  };
            break;
        case "Transportation":
            costRange = { min: 450000, max: 1400000, text:'Disruption of logistics and supply chains, ransomware, and theft of operational data.' };
            break;
        case "Media and telecom":
            costRange = { min: 550000, max: 1600000, text: 'Theft of intellectual property, disruption of services, and ransomware.'};
            break;
        default:
            costRange = { min: 0, max: 0 }; // Default case if sector is not found
            console.log('Sector not recognized.');
            break;
    }

    return costRange;
  };

  const ransomewareCost = getApproximateAverageCost(sectorName);

  const getApproximateAverageCostPhishing = (sectorName) => {
    let costRange = { min: 0, max: 0, text: '' };

    switch (sectorName) {
        case "Manufacturing":
            costRange = { min: 150000, max: 400000, text: 'Loss of intellectual property, financial theft, and disruption of operations.' };
            break;
        case "Finance and insurance":
            costRange = { min: 250000, max: 600000, text: 'Regulatory fines, loss of customer trust, and theft of financial data.' };
            break;
        case "Professional, business, and consumer services":
            costRange = { min: 100000, max: 300000, text: 'Business email compromise, data breaches, and loss of sensitive client information.' };
            break;
        case "Energy":
            costRange = { min: 200000, max: 500000, text: 'Disruption of operations, financial theft, and potential environmental hazards.' };
            break;
        case "Retail and wholesale":
            costRange = { min: 120000, max: 350000, text: 'Theft of payment card data, loss of customer trust, and financial theft.' };
            break;
        case "Education":
            costRange = { min: 100000, max: 250000, text: 'Theft of personal information of students and staff, disruption of educational services, and financial theft.' };
            break;
        case "Healthcare":
            costRange = { min: 150000, max: 400000, text: 'Theft of personal health information, disruption of medical services, and regulatory fines.' };
            break;
        case "Government":
            costRange = { min: 180000, max: 450000, text: 'Theft of sensitive data, disruption of public services, and potential national security risks.' };
            break;
        case "Transportation":
            costRange = { min: 140000, max: 380000, text: 'Disruption of logistics and supply chains, financial theft, and loss of operational data.' };
            break;
        case "Media and telecom":
            costRange = { min: 160000, max: 420000, text: 'Theft of intellectual property, disruption of services, and financial theft.' };
            break;
        default:
            costRange = { min: 0, max: 0, text: 'Sector not recognized.' };
            console.log('Sector not recognized.');
            break;
    }

    return costRange;
  };
  const phishingCost = getApproximateAverageCostPhishing(sectorName)

  const lineData = {
    labels: ['Almost instant-(Detection System In Place)', 'Within a few hours', 'Within a day', 'Several day', 'Over a week'],
    datasets: [
      {
        data: [27542, 61952, 72806, 78914, 104730],
        borderColor: '#ff6385',
        backgroundColor: '#ffb1c1',
        pointStyle: 'circle',
        pointRadius: 10,
        pointHoverRadius: 15
      },
    ],
  };

  const graphOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
    scales: {
      x: {
        grid: {
         
        },
        ticks: {
          display: false, // Hide x-axis labels
          
        },
      },
    }
    
  };

  const barData1 = {
    labels: ['Total', 'Australia ', 'Hong Kong', 'Indonesia', 'Japan', 'Malaysia', 'Philippines', 'Singapore'],
    datasets: [
      {
        label: 'Cyber Attack Experiences',
        data: [59, 72, 43, 52, 50, 76, 75, 46],
        backgroundColor: [
          pattern.draw('diagonal-right-left', '#ce003e' ),
          '#ff5e81',
          '#ff5e81',
          '#ff5e81',
          '#ff5e81',
          '#ff5e81',
          '#ff5e81',
          '#ff5e81',
          '#ff5e81'
        ],
        borderColor: [
          '#ff5e81'
        ],
        borderWidth: 1,
      },
    ],
  };

  const barData2 = {
    labels: ['Data loss', 'Business interruption', 'Reputational damage', 'Exfiltration or theft of intellectual property', 'Cost incurred', 'Ransom payment', 'Fines arrising from regulatory sactions'],
    datasets: [
      {
        label: 'Cyber security concerns',
        data: [55, 52, 32, 36, 37, 30, 35],
        backgroundColor: [
          '#ff5e81'
        ],
        borderColor: [
          '#ff5e81',
        ],
        borderWidth: 1,
      },
      {
        label: 'Actual impact of cyber incidents',
        data: [43, 43, 41, 22, 28, 22, 22],
        backgroundColor: [
          '#36a2eb'
        ],
        borderColor: [
          '#36a2eb',
        ],
        borderWidth: 1,
      },
    ],   
  };

  /* <tr class="table-row">
  <td class="table-cell">Data loss/exposure due to targeted attacks</td>
  <td class="table-cell">21</td>
</tr>
<tr class="table-row">
  <td class="table-cell">"Incidents involving non-computing connected devices"</td>
  <td class="table-cell">20</td>
</tr>
<tr class="table-row">
  <td class="table-cell">Electronic leakage of data from internal systems</td>
  <td class="table-cell">20</td>
</tr>
  <tr class="table-row">
  <td class="table-cell">Incidents affecting suppliers that we share data with</td>
<td class="table-cell">20</td>
</tr>
  <tr class="table-row">
  <td class="table-cell">Incidents affecting third party cloud services we use </td>
<td class="table-cell">19</td>
</tr>
<tr class="table-row">
  <td class="table-cell">Incidents affecting IT Infrastructure hosted by a third party </td>
  <td class="table-cell">18</td>
</tr>
<tr class="table-row">
  <td class="table-cell">Data loss/exposure due to attacks on point-of-sale (POS) systems (Bank/Retail only)</td>
  <td class="table-cell">2</td>
</tr>*/

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  const [isRendered, setIsRendered] = useState({
    slide1: true,
    slide2: false,
    slide3: false,
    slide4: false,
    slide5: false,
  });
  
  // Handler for slide change event
  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.realIndex; // Use realIndex for correct slide index
    if (activeIndex === 0 && !isRendered.slide1) {
      setIsRendered({ slide1: true, slide2: false, slide3: false, slide4: false, slide5: false });
    } else if (activeIndex === 1 && !isRendered.slide2) {
      setIsRendered({ slide1: false, slide2: true, slide3: false, slide4: false, slide5: false });
    } else if (activeIndex === 2 && !isRendered.slide3) {
      setIsRendered({ slide1: false, slide2: false, slide3: true, slide4: false, slide5: false });
    } else if (activeIndex === 3 && !isRendered.slide4) {
      setIsRendered({ slide1: false, slide2: false, slide3: false, slide4: true, slide5: false });
    } else if (activeIndex === 4 && !isRendered.slide5) {
      setIsRendered({ slide1: false, slide2: false, slide3: false, slide4: false, slide5: true });
    }
  };

  
 
  return (
    <div style={{backgroundColor: 'rgb(240, 242, 245)'}}>
      <div className="dashcontent">
        {adminTrue ? (
          <>
            <h1>{`Admin Dashboard for User - ${userOraganName}`}</h1>
            <div className="dashboard-container">
              <div className="stats-box ">
                <div className="icon-box black">
                  <SiCyberdefenders />
                </div>
                <div className="content-box">
                  <br></br>
                  <br></br>
                  <h4>{cyberRiskScore}%</h4>
                  <h3>Cyber Risk Score</h3>
                  <hr className="hrLine"></hr>
                  <p className="change-text">Just updated</p>
                </div>
              </div>
    
              <div className="stats-box ">
                <div className="icon-box blue">
                  <GiPayMoney/>
                </div>
                <div className="content-box">              
                  <br></br>
                  <br></br>
                  <h4>Loss of {ImpactOnRevenue} times Revenue</h4>
                  <h3 >Impact on Revenue</h3>
                  <hr className="hrLine"></hr>
                  <p className="change-text">Just updated</p>
                </div>
              </div>
    
              <div className="stats-box ">
                <div className="icon-box green">
                  <FaPiggyBank />
                </div>
                <div className="content-box">             
                  <br></br>
                  <br></br>
                  <h4>${AdjustedFinancialImpact} million</h4>
                  <h3 >Average Financial Impact</h3>
                  <hr className="hrLine"></hr>
                  <p className="change-text">Just updated</p>
                </div>
              </div>
    
              <div className="stats-box ">
                <div className="icon-box red">
                  <LuCalendarClock />
                </div>
                <div className="content-box">
                  <br></br>
                  <br></br>
                  <h4>{likelihoodOfAttackScore}%</h4>
                  <h3 >Likelihood of Attack in next 12 months</h3>
                  <hr className="hrLine"></hr>
                  <p className="change-text">Just updated</p>
                </div>
              </div>
            </div>
    
            <h2>Based on Sector</h2>
            <div className="dashboard-container level2">
              <div className="stats-box ">
                <div className="icon-box black">
                  <FaUserClock />
                </div>
                <div className="content-box"> 
                  <br></br>
                  <br></br>
                  <h4>{sectorScoreCal}%</h4>
                  <h3>Likelihood of Attack </h3>
                  <hr className="hrLine"></hr>
                  <p className="change-text">Based on Sector</p>
                </div>
              </div>
    
              <div className="stats-box ">
                <div className="icon-box blue">
                  <GiCash/>
                </div>
                <div className="content-box">
                  <br></br>
                  <br></br>
                  <h4>${AverageFinancialImpactbySector} million</h4>
                  <h3 >Average Financial Impact </h3>
                  <hr className="hrLine"></hr>
                  <p className="change-text">Based on Sector</p>
                </div>
              </div>
    
              <div className="stats-box ">
                <div className="icon-box green">
                  <FaMoneyBillWave />
                </div>
                <div className="content-box">
                  <br></br>
                  <br></br>
                  <h4>${ransomewareCost.min.toLocaleString()} to ${ransomewareCost.max.toLocaleString()}</h4>
                  <h3 >Average Cost of Ransomware Attack</h3>
                  <hr className="hrLine"></hr>
                  <p className="change-text">Based on Sector</p>
                </div>
              </div>
    
              <div className="stats-box ">
                <div className="icon-box red">
                  <FaMoneyBillWave />
                </div>
                <div className="content-box">
                  <br></br>
                  <br></br>
                  <h4>${phishingCost.min.toLocaleString()} to ${phishingCost.max.toLocaleString()}</h4>
                  <h3 >Average Cost of Phishing Attack</h3>
                  <hr className="hrLine"></hr>
                  <p className="change-text">Based on Sector</p>
                </div>
              </div>
            </div>
            <div class="dashboard-container level3">
              <Swiper
                  slidesPerView={'auto'}
                  spaceBetween={30}
                  centeredSlides={true}
                  loop={true}
                  pagination={pagination}
                  modules={[Pagination]}
                  className="mySwiper"
              >
                <SwiperSlide>
                <div class="dashboard-card ">
                          <div class="dashboard-card-content"> 
                            <div className="icontable orange">
                              <MdOutlineSecurity/>
                            </div>
                            <h4 class="card-title">Answers to 16 Questions </h4>
                            <p class="card-subtitle">Risk Score = {sixteenQuestRisk}</p>
                            <div className='Tableheader'>
                              <table class="dashboard-table">
                                <thead>
                                    <tr class="table-header">
                                        <th class="Sixteen-table-cell">Question</th>
                                        <th class="Sixteen-table-cell">Answer</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userAnswers.map((answer, index) => (
                                        <tr class="table-row orangetable" key={index}>
                                            <td class="Sixteen-table-cell">{answer.Question}</td>
                                            <td class="Sixteen-table-cell">{answer.answer}</td>
                                        </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                      </div>
                </SwiperSlide>
                  <SwiperSlide>
                      <div class="dashboard-card ">
                          <div class="dashboard-card-content"> 
                            <div className="icontable blue">
                              <AiFillSecurityScan/>
                            </div>
                            <h4 class="card-title">Types of security event experienced in the past 12 months </h4>
                            <p class="card-subtitle">(% of all businesses experiencing each type of attack)</p>
                            <div className='Tableheader'>
                              <table class="dashboard-table">
                              <thead>
                                  <tr class="table-header">
                                    <th class="table-cell">Type of Attack</th>
                                    <th class="table-cell">Percentage</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr class="table-row bluetable">
                                    <td class="table-cell">Viruses & Malware Causing a loss of productivity</td>
                                    <td class="table-cell"> 38</td>
                                  </tr>
                                    <tr class="table-row bluetable">
                                    <td class="table-cell">Inappropriate IT resource use by employees</td>
                                  <td class="table-cell">36</td>
                                  </tr>
                                  <tr class="table-row bluetable">
                                    <td class="table-cell">Physical loss of devices or media containing data</td>
                                    <td class="table-cell">31</td>
                                  </tr>
                                  <tr class="table-row bluetable">
                                    <td class="table-cell">Physical loss of mobile devices exposing the organisation to risk</td>
                                    <td class="table-cell">29</td>
                                  </tr>
                                  <tr class="table-row bluetable">
                                    <td class="table-cell">Inappropriate sharing of data via mobile devices</td>
                                    <td class="table-cell">27</td>
                                  </tr>
                                  <tr class="table-row bluetable">
                                    <td class="table-cell">Loss of access to internal services</td>
                                    <td class="table-cell">25</td>
                                  </tr>
                                  <tr class="table-row bluetable">
                                    <td class="table-cell">Loss of access to customer-facing services</td>
                                    <td class="table-cell">25</td>
                                  </tr>
                              </tbody>
                              </table>
                            </div>
                          </div>
                      </div>
                  </SwiperSlide>
                  <SwiperSlide>
                      <div class="dashboard-card">
                          <div class="dashboard-card-content">
                            <div className="icontable green">
                              <FaCoins/>
                            </div>
                            <h4 class="card-title">The Breakdown of an average finacial impact of a data breach</h4>
                            <p class="card-subtitle">Financial Impact Large (LBs)</p>
                            <div className='Tableheader'>
                                <table class="dashboard-table">
                                <thead>
                                    <tr class="table-header">
                                      <th class="table-cell">Category</th>
                                      <th class="table-cell">Cost</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">Additional Internal Staff Wages</td>
                                      <td class="table-cell">$14138</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">Lost Business</td>
                                      <td class="table-cell">$12561</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">Employing External Professionals</td>
                                      <td class="table-cell">$10875</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">Damage to Credit Rating/Insurance Premiums</td>
                                      <td class="table-cell">$9472</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">Extra PR (to repair brand damage)</td>
                                      <td class="table-cell">$7899</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">Compensation</td>
                                      <td class="table-cell">$$7524</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">Improving Software & Infrastructure</td>
                                      <td class="table-cell">$9733</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">Training</td>
                                      <td class="table-cell">$7418</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">New Staff</td>
                                      <td class="table-cell">$6901</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                          </div>
                      </div>
                  </SwiperSlide>
                  <SwiperSlide>
                      <div class="dashboard-card">
                          <div class="dashboard-card-content">
                            <div className="icontable red">
                              <MdStore/>
                            </div>
                            <h4 class="card-title">Average Financial Impact to Small and Medium Size Businesses (SMBs)</h4>
                            <p class="card-subtitle">Financial Impact (SMBs)</p>
                            <div className='Tableheader '>
                                <table class="dashboard-table">
                                <thead>
                                    <tr class="table-header">
                                      <th class="table-cell">Category</th>
                                      <th class="table-cell">Cost</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="table-row redtable">
                                      <td class="table-cell">Additional Internal Staff Wages</td>
                                      <td class="table-cell">$125938</td>
                                    </tr>
                                    <tr class="table-row redtable">
                                      <td class="table-cell">Lost Business</td>
                                      <td class="table-cell">$105800</td>
                                    </tr>
                                    <tr class="table-row redtable">
                                      <td class="table-cell">Employing External Professionals</td>
                                      <td class="table-cell">$85642</td>
                                    </tr>
                                    <tr class="table-row redtable">
                                      <td class="table-cell">Damage to Credit Rating/Insurance Premiums</td>
                                      <td class="table-cell">$116131</td>
                                    </tr>
                                    <tr class="table-row redtable">
                                      <td class="table-cell">Extra PR (to repair brand damage)</td>
                                      <td class="table-cell">$90686</td>
                                    </tr>
                                    <tr class="table-row redtable">
                                      <td class="table-cell">Compensation</td>
                                      <td class="table-cell">$92361</td>
                                    </tr>
                                    <tr class="table-row redtable">
                                      <td class="table-cell">Improving Software & Infrastructure</td>
                                      <td class="table-cell">$118780</td>
                                    </tr>
                                    <tr class="table-row redtable">
                                      <td class="table-cell">Training</td>
                                      <td class="table-cell">$78817</td>
                                    </tr>
                                    <tr class="table-row redtable">
                                      <td class="table-cell">New Staff</td>
                                      <td class="table-cell">$76769</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                          </div>
                      </div>
                  </SwiperSlide>
                  <SwiperSlide>
                      <div class="dashboard-card">
                          <div class="dashboard-card-content">
                            <div className="icontable blue">
                              <RiSwordFill/>
                            </div>
                            <h4 class="card-title">Cost to Small and Medium Size Business by Attack Type</h4>
                            <p class="card-subtitle">Cost to (SMBs)</p>
                            <div className='Tableheader '>
                                <table class="dashboard-table">
                                <thead>
                                    <tr class="table-header">
                                      <th class="table-cell">Type of Attack </th>
                                      <th class="table-cell">Cost</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="table-row bluetable">
                                      <td class="table-cell">Previously unknown “zero day” vulnerability</td>
                                      <td class="table-cell">$149131</td>
                                    </tr>
                                    <tr class="table-row bluetable">
                                      <td class="table-cell">Targeted Attack</td>
                                      <td class="table-cell">$143247</td>
                                    </tr>
                                    <tr class="table-row bluetable">
                                      <td class="table-cell">Exploits/loss through mobile devices</td>
                                      <td class="table-cell">$130405</td>
                                    </tr>
                                    <tr class="table-row bluetable">
                                      <td class="table-cell">Actions of politically-motivated “hacktivists”</td>
                                      <td class="table-cell">$127918</td>
                                    </tr>
                                    <tr class="table-row bluetable">
                                      <td class="table-cell">Actions by competitors</td>
                                      <td class="table-cell">$122471</td>
                                    </tr>
                                    <tr class="table-row bluetable">
                                      <td class="table-cell">Vulnerabilities in code developed ourselves</td>
                                      <td class="table-cell">$115580</td>
                                    </tr>
                                    <tr class="table-row bluetable">
                                      <td class="table-cell">DDoS attack</td>
                                      <td class="table-cell">$118780</td>
                                    </tr>
                                    <tr class="table-row bluetable">
                                      <td class="table-cell">Known, unpatched exploits in off-the-shelf software/hardware</td>
                                      <td class="table-cell">$101598</td>
                                    </tr>
                                    <tr class="table-row bluetable">
                                      <td class="table-cell">Crypto-malware/ransomware</td>
                                      <td class="table-cell">$98676</td>
                                    </tr>
                                    <tr class="table-row bluetable">
                                      <td class="table-cell">Phishing/social engineering</td>
                                      <td class="table-cell">$98079</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                          </div>
                      </div>
                  </SwiperSlide>
                  <SwiperSlide>
                      <div class="dashboard-card">
                          <div class="dashboard-card-content">
                            <div className="icontable green">
                              <BsShieldFillExclamation/>
                            </div>
                            <h4 class="card-title">Cause of Reported Incidents</h4>
                            <p class="card-subtitle">Reported Incidents</p>
                            <div className='Tableheader '>
                                <table class="dashboard-table">
                                <thead>
                                    <tr class="table-header">
                                      <th class="table-cell">Incident Type</th>
                                      <th class="table-cell">Percentage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="table-row greentable">
                                      <td class="table-cell"> Malware (ransomware, spyware, viruses, etc.)</td>
                                      <td class="table-cell">23</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">Phishing</td>
                                      <td class="table-cell">16</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">Password attack</td>
                                      <td class="table-cell">13</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">Denial-of-service  (DDoS)</td>
                                      <td class="table-cell">11</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">Insider threat</td>
                                      <td class="table-cell">7</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">Cross-site scripting</td>
                                      <td class="table-cell">7</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">Man-in-the-middle (MitM)</td>
                                      <td class="table-cell">6</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">SQL injections</td>
                                      <td class="table-cell">6</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">Zero-day exploit</td>
                                      <td class="table-cell">5</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">Something else</td>
                                      <td class="table-cell">4</td>
                                    </tr>
                                    <tr class="table-row greentable">
                                      <td class="table-cell">N/A I don’t know what the cause was</td>
                                      <td class="table-cell">3</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                          </div>
                      </div>
                  </SwiperSlide>
                  <SwiperSlide>
                      <div class="dashboard-card">
                        <div className="icontable red">
                          <GiSwordsEmblem/>
                        </div>
                        <div class="dashboard-card-content">
                          <h4 class="card-title">Likelihood of Attack by Sector</h4>
                          <p class="card-subtitle">Attack by Sector</p>
                          <div className='Tableheader'>
                            <table class="dashboard-table">
                            <thead>
                                <tr class="table-header">
                                  <th class="table-cell">Sector</th>
                                  <th class="table-cell">Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="table-row redtable">
                                  <td class="table-cell">Manufacturing</td>
                                  <td class="table-cell">24.80</td>
                                </tr>
                                <tr class="table-row redtable">
                                  <td class="table-cell">Finance and insurance</td>
                                  <td class="table-cell">18.90</td>
                                </tr>
                                <tr class="table-row redtable">
                                  <td class="table-cell">Professional, business, and consumer services</td>
                                  <td class="table-cell">14.60</td>
                                </tr>
                                <tr class="table-row redtable">
                                  <td class="table-cell">Energy</td>
                                  <td class="table-cell"> 10.70</td>
                                </tr>
                                <tr class="table-row redtable">
                                  <td class="table-cell">Retail and wholesale</td>
                                  <td class="table-cell">8.70</td>
                                </tr>
                                <tr class="table-row redtable">
                                  <td class="table-cell">Education  </td>
                                  <td class="table-cell">7.30</td>
                                </tr>
                                <tr class="table-row redtable">
                                  <td class="table-cell">Healthcare</td>
                                  <td class="table-cell"> 5.80</td>
                                </tr>
                                <tr class="table-row redtable">
                                  <td class="table-cell">Government</td>
                                  <td class="table-cell"> 4.80</td>
                                </tr>
                                <tr class="table-row redtable">
                                  <td class="table-cell">Transportation</td>
                                  <td class="table-cell"> 3.90</td>
                                </tr>
                                <tr class="table-row redtable">
                                  <td class="table-cell">Media and telecom</td>
                                  <td class="table-cell"> 0.50</td>
                                </tr>
                            </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                  </SwiperSlide>    
              </Swiper>
            </div>
            <div className='dashboard-container level4'>
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                pagination={pagination}
                modules={[Pagination]}
                className="mySwiper"
                observer={true}
                observeParents={true}
                lazy={true}
                loop={true}
                onSlideChange={handleSlideChange}
              >
                {/* Slide 1 */}
                <SwiperSlide>
                  <div className="dashboard-card">
                    <div className="dashboard-card-content">
                      <div className="Tableheader">
                        <div className="chart-container">
                          {/* Show loading animation until chart is ready */}
                          {!isRendered.slide1 ? (
                            <div className="loader">Loading...</div>
                          ) : (
                            <Line data={lineData} options={graphOptions} />
                          )}
                        </div>
                      </div>
                      <h4 className="card-title">Financial Impact Over time to SMBs – time to detection</h4>
                      <p className="card-subtitle">Financial Impact Over time</p>
                      <hr className="card-divider" />
                    </div>
                  </div>
                </SwiperSlide>
      
                {/* Slide 2 */}
                <SwiperSlide>
                  <div className="dashboard-card">
                    <div className="dashboard-card-content">
                      <div className="Tableheader">
                        <div className="chart-container">
                          {!isRendered.slide2 ? (
                            <div className="loader">Loading...</div>
                          ) : (
                            <Bar data={barData1} options={graphOptions} />
                          )}
                        </div>
                      </div>
                      <h4 className="card-title">Proportion of Businesses that have experienced a Cyber Attack</h4>
                      <p className="card-subtitle">Cyber Attack Experiences</p>
                      <hr className="card-divider" />
                    </div>
                  </div>
                </SwiperSlide>
      
                {/* Slide 3 */}
                <SwiperSlide>
                  <div className="dashboard-card">
                    <div className="dashboard-card-content">
                      <div className="Tableheader">
                        <div className="chart-container">
                          {!isRendered.slide3 ? (
                            <div className="loader">Loading...</div>
                          ) : (
                            <Bar data={barData2} options={graphOptions} />
                          )}
                        </div>
                      </div>
                      <div>
                        <FaSquare className="keyicons" /> <span>= Cyber security concerns</span>
                      </div>
                      <FaSquare className="keyicons2" /> <span>= Actual impact of cyber incidents</span>
                      <h4 className="card-title">Cyber Security Concerns and Impact of Actual Incidents</h4>
                      <p className="card-subtitle">Cyber Security Concerns</p>
                      <hr className="card-divider" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="dashboard-card">
                    <div className="dashboard-card-content">
                      <img className='graphSize' src='Graph_updated.png' alt='Causes of Cyber Incidents' />
                      <h4 className="card-title" >Causes of Cyber Incidents</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="dashboard-card">
                    <div className="dashboard-card-content">
                      <img className='graphSize' src='Graph2_updated.png' alt='Impact of Cyber Incidents' />
                      <h4 className='card-title'>Impact of Cyber Incidents</h4>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <p className='copyright'>© Copyright, 2024 Assentian. All Rights Reserved</p>
          </>
        ):( 
          <>
            <h1>You do not have Admin Permissions</h1>
            <Copyright/>
          </>
        )}
      </div>
    </div>
  );
};
    

export default NewAdminDashboard;