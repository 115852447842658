
import CveListComponent from './ThreatComp';
import React, { useState, useEffect } from 'react';


function ThreatIntel() {
  const [glossaryData, setGlossaryData] = useState([]);
 //Fetches the glossary data from json file
  useEffect(() => {
    fetch('/glossary-export.json')
      .then(response => response.json())
      .then(data => {
        console.log('Fetched data:', data); 
        setGlossaryData(data.parentTerms);
      })
      .catch(error => console.error('Error fetching glossary data:', error));
  }, []);

    //Calls the Cve Component which is the main functionality for the threat intelligence and passes in the glossary data. 
    return (
      <div  >
        <CveListComponent glossaryData={glossaryData}/>
      </div>
    );
  }
  
  export default ThreatIntel;