import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { db, auth } from '../Firebase_Config/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { FaUser } from 'react-icons/fa';
import Alert from '@mui/material/Alert';

import './admin.css';
import Copyright from '../CopyrightFooter/Copyright';

const AdminDashboard = () => {
    const [users, setUsers] = useState([]);
    const [adminTrue, setAdminTrue] = useState(null);
    //Fetches all non Admin users
    useEffect(() => {
        const fetchAdminAndUsers = async () => {
            try {
                const userId = getUserId();

                // Fetch admin status
                const userDocQuery = query(collection(db, 'users'), where('userId', '==', userId));
                const userDocSnapshot = await getDocs(userDocQuery);

                if (!userDocSnapshot.empty) {
                    const userDocData = userDocSnapshot.docs[0].data();
                    const isAdminTrue = userDocData.isAdmin || null;
                    setAdminTrue(isAdminTrue);
                } else {
                    console.log('User document not found for user ID:', userId);
                }

                // Fetch non-admin users
                const usersRef = collection(db, 'users');
                const q = query(usersRef, where('isAdmin', '==', false));
                const querySnapshot = await getDocs(q);

                const fetchedUsers = [];
                querySnapshot.forEach(doc => {
                    fetchedUsers.push({ id: doc.id, ...doc.data() });
                });
                setUsers(fetchedUsers);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchAdminAndUsers();
    }, []);

    const getUserId = () => {
        const user = auth.currentUser;
        return user.uid;
    };

    return (
        <div style={{
            backgroundColor: 'rgb(240, 242, 245)', 
            minHeight: '100vh', 
            minWidth: '100vw', 
            margin: 0, 
            padding: 0
          }}>
            {adminTrue ? (
                <div className='Admincontent'>
                    <div className='Adminheader'>
                        <h1 className='textCentre'>Administrator Dashboard</h1>
                    </div>
                    <h2>List of Users</h2>
                    <Alert variant="outlined" severity="warning">Admins have full access to users assessments. So any changes that Admins make will affects users and they will see those changes.</Alert>
                    <div className="cardContainer">
                        {users.map(user => (
                            user.isAssessmentComplete && (
                               
                                <div className="Admin-stats-box">
                                    <div className="Admin-icon-box red">
                                        <FaUser />
                                    </div>
                                    <div className="Admin-content-box">
                                        <br></br>
                                        <br></br>
                                        <h4>Name: {user.OrganName}</h4>
                                        <Link to={{ pathname: "/AdminAssessmentPage", search: `?id=${user.userId}` }}>
                                            <button className='AssessmentDashButton' size="small">
                                                {'Assessment Page'}
                                            </button>
                                        </Link>
                                        <Link to={{ pathname: "/AdminAssessmentDashboard", search: `?id=${user.userId}` }}>
                                            <button className='AssessmentDashButton' size="small">
                                                {'Assessment Dashboard'}
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            )
                        ))}
                    </div>
                </div>
            ) : (
                <div className='Admincontent'>
                    <h1>You do not have Admin Permissions</h1>
                </div>
            )}
            <Copyright/>
        </div>
    );
};

export default AdminDashboard;
