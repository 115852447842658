import React, { useState, useEffect } from 'react';
import './assessment.css'; // You can style the components here
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdOutlineCenterFocusWeak } from "react-icons/md";
import {  LuTarget } from "react-icons/lu";
import Alert from '@mui/material/Alert';
import { db, auth, st} from '../Firebase_Config/firebase';
import { collection, addDoc, getDoc, query, where, orderBy, limit,  doc, updateDoc, setDoc, getDocs } from 'firebase/firestore';
import { getStorage, ref, listAll, deleteObject, uploadBytes, getDownloadURL  } from 'firebase/storage';
import axios from 'axios';


//Function for saving the answers to the firebase 
const saveAnswersToFirebase = async ( userId, sixAnswers, frequencies) => {
  try {
    const userDocRef = doc(db, 'Answers', userId);
    // Combine answers with formattedAnswers into a single object
    const formattedAnswers = {
      Sixteen_Answers: sixAnswers, // Include the sixteen answers
      Frequencies: frequencies // Include frequencies
    };
    
    await setDoc(userDocRef, formattedAnswers, { merge: true }); // Merge updates into the existing document
    console.log('Answers saved to Firebase.');
  } catch (error) {
    console.error('Error saving answers to Firebase:', error);
  }
};

//Component for rendering the 16 questions 
const CollapsibleSixteen = ({ title, questionIndex, onAnswerChange, currentAnswer }) => {
  const handleChange = (e) => {
    onAnswerChange(questionIndex, e.target.value);
  };

  return (
    <div className="collapsible">
      <div className="collapsible-header">
        <div className='sixArrow'>
          <MdOutlineCenterFocusWeak className='AIicon2'/>
          <span>{title}</span>
        </div>
        <div className="collapsible-status">
          <form >
            <label>
              <input
                type="radio"
                value="yes"
                checked={currentAnswer === 'yes'}
                onChange={handleChange}
              />
              <span className="radioText">Yes</span>
            </label>
            <label>
              <input
                type="radio"
                value="no"
                checked={currentAnswer === 'no'}
                onChange={handleChange}
              />
              <span className="radioText">No</span>
            </label>
          </form>
        </div>
      </div>
    </div>
  );
};








// Main Component
const SecurityAssessment = () => {

  const sixteenQuestions = [
    {text: 'Does your organisation have a user administration process for new employees and leaving employees?'},
    {text: 'Does your organisation review user privileges?'},
    {text: 'Are complex passwords enforced within the organisation?'},
    {text: 'Do employees, individuals or third parties have remote access to your network?'},
    {text: 'Does your organisation provide security awareness training?'},
    {text: 'Are the laptops/desktops of employees encrypted?'},
    {text: 'Are backups stored remotely, and if so are they properly protected?'},
    {text: 'Are patches installed on a timely basis?'},
    {text: 'Does your organisation have a wireless corporate network?'},
    {text: 'Does your organisation have a wireless guest network?'},
    {text: 'Does your organisation have security & privacy policies?'},
    {text: 'Does your organisation have an incident response and recovery plan?'},
    {text: 'Does your organisation have an anti-virus?'},
    {text: 'Does your organisation protect its environment with a firewall?'},
    {text: 'Does your organisation perform internal vulnerability scans?'},
    {text: 'Was your organisation in the past year a victim of a cyber attack?'}
  ]

  const [sixteenAnswers, setSixteenAnswers] = useState([]);
 
  //Fetch any exsisitng answers from the user's answer doument and if not populate with null 
  useEffect(() => {
    const fetchAnswers = async () => {
      const userId = getUserId(); // Get user ID
      const answerDocRef = doc(db, 'Answers', userId);
      try {
        const answerDoc = await getDoc(answerDocRef);
        if (answerDoc.exists()) {
          const answerData = answerDoc.data();
          const savedAnswers = answerData.Sixteen_Answers || []; // Fallback to empty array
          setSixteenAnswers(savedAnswers); // Load saved answers
          console.log('Loaded saved answers:', savedAnswers);
          
          checkSixteenQuestionsAnswered()
        } else {
          // New user, initialize with empty answers
          setSixteenAnswers(new Array(16).fill({ answer: null })); // Set default for new users
          console.log('New user detected, initializing answers.');
        }
      } catch (error) {
        console.error('Error fetching saved answers:', error);
      }
    };
  
    fetchAnswers(); // Fetch answers on component mount
  }, []); // Empty dependency array means this runs once
  
 

  

   
 //AutoSave Function to save answers every one second to ensure answered are saved.
  useEffect(() => {
      const autoSaveInterval = setInterval(async () => {
        const user = auth.currentUser;
        if (user) {
          try {
            const totalYes = sixteenAnswers.filter(answer => answer.answer === 'yes').length;
            const totalNo = sixteenAnswers.filter(answer => answer.answer === 'no').length;
            // Save answers to Firebase
            const calcuateFrequencies = { yes: totalYes, no: totalNo }
            await saveAnswersToFirebase( user.uid, sixteenAnswers, calcuateFrequencies);
            
           
            const riskScore = totalNo * 6.25;
            console.log('NO SCORE:', totalNo)
            console.log('RISK SCORE:', riskScore)
            // Save the final risk score to Firebase
            const userDocRef = doc(db, 'Answers', user.uid);
            await updateDoc(userDocRef, {
              
              Risk_Score: riskScore
            });

          
            const sixteenAnswered = await checkSixteenQuestionsAnswered();
            if (sixteenAnswered) {
              console.log("All questions are answered. Updated Firebase with isSixteenComplete: true");
            }

          } catch (error) {
            console.error('Error in auto-save:', error);
          }
        }
      }, 1000);
      return () => clearInterval(autoSaveInterval);
  }, [sixteenAnswers]);

  const getUserId = () => {
    const user = auth.currentUser;
    return user.uid;
  };

  const handleAnswerChange = (questionIndex, answer) => {
    setSixteenAnswers((prevAnswers = []) => { // Default to empty array if prevAnswers is undefined
      const updatedAnswers = [...prevAnswers]; // Create a copy of the current answers array
      updatedAnswers[questionIndex] = {
        Question: sixteenQuestions[questionIndex].text, // Correctly map the question text
        answer: answer, // Save the user's answer ("yes" or "no")
      };
      return updatedAnswers; // Return the updated array to update the state
    });
  };
  /*Function to check if all questions have been answered if so for the first time the user is redirected to the 
  full assessment then the redirect  is disabled if the user returns when they need to change their answers*/

  const checkSixteenQuestionsAnswered = async () => {
    const userId = getUserId(); // Get current user ID
    const answerDocRef = doc(db, 'Answers', userId);
    const q = query(collection(db, 'users'), where('userId', '==', userId));
    try {
      const answerDoc = await getDoc(answerDocRef);
      const querySnapshot = await getDocs(q);
      if (answerDoc.exists() && !querySnapshot.empty) {
        const answerData = answerDoc.data();
        const sixteenAnswers = answerData.Sixteen_Answers || [];
        const userDocument = querySnapshot.docs[0];
        const userDocData = userDocument.data();
  
        // If DashReroute is already true, do nothing
        if (userDocData.isSixteenComplete === true) {
          console.log('Questions already Answered skipping rerouting.');
          return false;
        }
  
        // Check if all sixteen questions have been answered
        for (let i = 0; i < 16; i++) {
          const answer = sixteenAnswers[i];
          if (!answer || (answer.answer !== 'yes' && answer.answer !== 'no')) {
            console.log(`Question ${i + 1} is missing or not answered.`);
            return false;
          }
        }
  
        // If all questions have been answered, update user document

        try {
          await updateDoc(userDocument.ref, {
            isSixteenComplete: true,  // Mark the assessment as complete
          });
          console.log('User document updated with isSixteenComplete: true');
  
          // Optionally redirect to dashboard
          setTimeout(() => {
            window.location.href = '/Assessment';  // Reroute to the dashboard
          }, 500);
  
        } catch (error) {
          console.error('Error updating user document:', error);
        }
        return true;

      } else {
        console.log('User document not found.');
        return false;
      }
    } catch (error) {
      console.error('Error fetching user document:', error);
      return false;
    }
  };
  //Function to render the 16 questions 
  const renderCollapsibleQuestions = () => {
    return sixteenQuestions.map((question, index) => (
      <CollapsibleSixteen
        key={index}
        title={question.text}
        questionIndex={index}
        currentAnswer={sixteenAnswers[index]?.answer} // Ensure safe access
        onAnswerChange={handleAnswerChange}
      />
    ));
  };
  //HTML for frontend 
  return (
    <div >
      <div className="AssessmentContent">
        <h2 className='titleText'>Security Assessment</h2>
        <p className='sloganText'>Quantify, Mitigate, Secure: Unleash the Power of Data-Driven Cyber Risk Insights</p>
        <div>{renderCollapsibleQuestions()}</div>
        <p className='copyright'>© Copyright, 2024 Assentian. All Rights Reserved</p>
      </div>
     </div> 
  );
};

export default SecurityAssessment;


