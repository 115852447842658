import React, { useState } from 'react';
import axios from 'axios';
import './AiTextGen.css'; // Import CSS file for styling
import Copyright from '../CopyrightFooter/Copyright';

function CohereInterface() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  //Sends the message to the API to be processed and the result is returned
  const sendMessage = async (e) => {
    e.preventDefault();
    if (input.trim() === '') return;

    const userMessage = { sender: 'user', text: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput('');
    setIsTyping(true);

    try {
      const response = await axios.post(
        'https://api.cohere.ai/v1/chat',
        {
          query: input, // User input goes here
          message_history: messages.map((msg) => ({
            user_name: msg.sender === 'user' ? 'User' : 'Bot',
            text: msg.text,
          })),
        },
        {
          headers: {
            Authorization: `Bearer BOgLrDeF6HF9kRfSlqFHUQsTAz0nomrhGd0PewI0`, // Replace with your API key
            'Content-Type': 'application/json',
          },
        }
      );

      const botResponse = response.data.text.trim();
      const botMessage = { sender: 'bot', text: botResponse };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setIsTyping(false);
    } catch (error) {
      console.error('Error generating response:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: 'Error generating response. Please try again.' },
      ]);
      setIsTyping(false);
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };
 //HTML for the frontend 
  return (
    <div style={{
      backgroundColor: 'rgb(240, 242, 245)',
      height: '108vh', 
    }}>
      <div className="Aicontent">
        <div className="AIContainerBox">
          <div className="Aiheader">
            <h1 className="AIh1">Cyber-Security Co-Pilot</h1>
          </div>
          <div className="chatbot-container">
            <div className="chat-messages">
              {messages.map((msg, idx) => (
                <div key={idx} className={`message ${msg.sender}`}>
                  {msg.text}
                </div>
              ))}
              {isTyping && <div className="typing-indicator">Typing...</div>}
            </div>
            <div className="inputBox">
              <form onSubmit={sendMessage}>
                <input
                  className="promptInput"
                  type="text"
                  value={input}
                  onChange={handleInputChange}
                  placeholder="Type a message..."
                />
                <button className="aibutton" type="submit">
                  <svg
                    height="24"
                    width="24"
                    fill="#FFFFFF"
                    viewBox="0 0 24 24"
                    data-name="Layer 1"
                    id="Layer_1"
                    className="sparkle"
                  >
                    <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                  </svg>
                  <span className="aitext">Answer</span>
                </button>
              </form>
            </div>
          </div>
          <p className='copyright'>© Copyright, 2024 Assentian. All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
}

export default CohereInterface;
