import React, { useState, useEffect } from 'react';
import './assessment.css'; // You can style the components here
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdOutlineCenterFocusWeak } from "react-icons/md";
import {  LuTarget } from "react-icons/lu";
import Alert from '@mui/material/Alert';
import { Navigate } from 'react-router-dom';
import { db, auth, st} from '../Firebase_Config/firebase';
import { collection, addDoc, getDoc, query, where, orderBy, limit,  doc, updateDoc, setDoc, getDocs } from 'firebase/firestore';
import { getStorage, ref, listAll, deleteObject, uploadBytes, getDownloadURL  } from 'firebase/storage';
import axios from 'axios';


//Function Save the answers to firebase 
const saveAnswersToFirebase = async (answers, userId) => {
  try {
    const userDocRef = doc(db, 'Answers', userId);
    // Combine answers with formattedAnswers into a single object
    const formattedAnswers = {
      ...answers, // Spread the existing answers
    };
    
    await setDoc(userDocRef, formattedAnswers, { merge: true }); // Merge updates into the existing document
    console.log('Answers saved to Firebase.');
  } catch (error) {
    console.error('Error saving answers to Firebase:', error);
  }
};

// Function to load answers from Firebase
const loadUserAnswersFromFirebase = async (userId) => {
  try {
    const userDocRef = doc(db, 'Answers', userId);
    const userAnswers = await getDoc(userDocRef);
    
    if (userAnswers.exists()) {
      return userAnswers.data(); // Return the saved answers
    } else {
      console.log('No answers found.');
      return {}; // Return an empty object if no documents are found
    }
  } catch (error) {
    console.error('Error loading answers from Firebase:', error);
    return {}; // Return an empty object in case of error
  }
};


// Modal Component for Updating Progress
const ProgressModal = ({ 
  isOpen, 
  onClose, 
  onSave, 
  currentProgress, 
  savedComment,
  hasNotSureOption,  // New prop to handle checkbox visibility
  notApplicableSelected: initialNotApplicable = false, // Initialize notApplicable 
}) => {
  const [progressValue, setProgressValue] = useState(currentProgress || null);
  const [comment, setComment] = useState(savedComment || '');
  const [fileToUpload, setFileToUpload] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [error, setError] = useState('');
  const [notApplicableSelected, setNotApplicableSelected] = useState(initialNotApplicable); // Checkbox state
  const storage = getStorage();

  useEffect(() => {
    fetchUploadedFiles();
  });

  useEffect(() => {
    setProgressValue(currentProgress); // Update state when modal opens with new props
    setComment(savedComment);
  }, [currentProgress, savedComment]);


  const getUserId = () => {
    const user = auth.currentUser;
    return user.uid;
  };
  //Fetch Uploaded files from the firebase storage bucket 
  const fetchUploadedFiles = async () => {
    try {
      const userId = getUserId();
      const storageRef = ref(storage, `evidence/${userId}`);
      const fileList = await listAll(storageRef);
  
      const files = await Promise.all(fileList.items.map(async (item) => {
        try {
          const url = await getDownloadURL(item);
          return {
            name: item.name,
            url: url
          };
        } catch (error) {
          // If a file doesn't exist, handle it by logging or filtering it out
          if (error.code === 'storage/object-not-found') {
            console.warn(`File ${item.name} not found, skipping...`);
            return null;  // Return null for files that don't exist
          } else {
            console.error('Error fetching file URL: ', error);
            return null;  // Or handle other errors as needed
          }
        }
      }));
  
      // Filter out any null values (files that failed to fetch)
      const validFiles = files.filter(file => file !== null);
      
      setUploadedFiles(validFiles);
    } catch (error) {
      console.error('Error fetching uploaded files: ', error);
      alert('Error fetching uploaded files: ', error);
    }
  };
  //Function to set the file ready for upload
  const handleFileUpload = e => {
    const file = e.target.files[0];
    setFileToUpload(file);
  };
  //Upload the chosen file to firebase 
  const uploadEvidence = async () => {
    if (!fileToUpload) {
      console.error('No file selected.');
      alert('No file selected.');
      return;
    }
  
    const userId = getUserId();
    const storageRef = ref(storage, `evidence/${userId}/${fileToUpload.name}`);
  
    try {
      await uploadBytes(storageRef, fileToUpload);
      console.log('File uploaded successfully.');
      alert('File uploaded successfully.');
      setFileToUpload(null); // Clear the uploaded file after successful upload
      fetchUploadedFiles(); // Refresh the list of uploaded files
    } catch (error) {
      console.error('Error uploading file: ', error);
      alert('Error uploading file: ', error);
    }
  };
  //Remove a file from firebase 
  const removeFile = async (indexToRemove, fileName) => {
    try {
      const userId = getUserId();
      const storageRef = ref(storage, `evidence/${userId}/${fileName}`);
      
      // Delete file from Firebase storage
      await deleteObject(storageRef);
      
      // Fetch the updated list of files after deletion
      await fetchUploadedFiles(); 
  
      console.log('File deleted successfully.');
      alert('File deleted successfully.');
    } catch (error) {
      console.error('Error deleting file: ', error);
      alert('Error deleting file: ', error);
    }
  };
  
  //Save button in the Pop up menu 
  const handleSave = () => {
    if (!notApplicableSelected) {
      // Validate the progress value if checkbox is not selected
      if (progressValue === null || !Number.isInteger(Number(progressValue)) || progressValue < 0 || progressValue > 100) {
        setError('Please enter a whole number between 0 and 100');
        return;
      }
    }

    setError('');
    // If the checkbox is checked, save the percentage as null and set the flag
    onSave(notApplicableSelected ? null : progressValue, comment, notApplicableSelected);
  };
  

  if (!isOpen) return null; // Don't render if the modal is closed
 //HTML for the pop up menu
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Progress Update</h3>
        {error && <Alert variant="filled" severity="error" className="error-message">{error}</Alert>}
        <label>Current Progress (Expected: 100%)</label>
        <input
          type="number"
          value={progressValue !== null ? progressValue : ''} // Display empty if progressValue is null
          onChange={(e) => setProgressValue(e.target.value === '' ? null : Number(e.target.value))}
          min="0"
          max="100"
          step="1"
          placeholder="Enter progress"
          disabled={notApplicableSelected}
        />
        {hasNotSureOption && (
          <label>
            <input
              type="checkbox"
              checked={notApplicableSelected}
              onChange={(e) => setNotApplicableSelected(e.target.checked)}
            />
            <span className='Assessmentcheckbox'></span>
            Not Applicable
          </label>
          
        )}

        <textarea
          placeholder="Leave a quick update"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <div>
        <p>Upload Files Here:</p>
        <label className="custom-file-upload">
            Choose File
            <input
                type="file"
                onChange={handleFileUpload}
                required
            />
        </label>
        
        <button className='fileButton' onClick={uploadEvidence}>Upload Evidence</button>
        {fileToUpload && (
          <p>Selected File: {fileToUpload.name}</p>
        )}
        </div>
        {uploadedFiles.map((file, index) => (
          <div key={index}>
            <span>{file.name}</span>
            <button className='fileButton remove' onClick={() => removeFile(index, file.name)}>Remove</button>
          </div>
        ))}

        <div className="modal-actions">
          <button onClick={onClose}>Cancel</button>
          <button onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
};



// Collapsible Component
const Collapsible = ({
  title,
  summaryText,
  children,
  savedAnswers,  // Receive saved data
  answers,
  setAnswers,
  groupTitle,
  notApplicable
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  

  // Ensure percentage is handled correctly (0 is a valid value, but undefined means no value)
  const handleModalSave = (newPercentage, comment, notApplicableSelected) => {
    const clauseAndSummaryText = `${title} - ${summaryText}`;
    const updatedAnswers = {
      ...answers,
      [groupTitle]: {
        ...(answers[groupTitle] || {}),
        [clauseAndSummaryText]: {
          percentage: newPercentage,
          comment: comment,
          notApplicableSelected, // Save notApplicableSelected flag
        },
      },
    };

    setAnswers(updatedAnswers);
    setIsModalOpen(false);
  };

  const currentRiskLevel = savedAnswers?.notApplicableSelected ? 'Not Applicable' : 'Progress';

  // Correctly handle displaying saved percentage: 'NA%' if undefined, valid number otherwise
  const displayPercentage = savedAnswers?.percentage !== null 
    ? `${savedAnswers.percentage}%` 
    : 'NA%';  // Show 'NA%' if the percentage is null

  return (
    <div className="collapsible">
      <div className="collapsible-header-2nd">
        <div className="arrow" onClick={() => setIsOpen(!isOpen)}>
          <LuTarget className='AIicons'/>
          {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
          <span>{title} - {summaryText}</span>
        </div>
        <div className="collapsible-status">
          <span>Suggested Artifact</span>
          <div className="progress-bar-container">
            <div
              className="progress-bar"
              style={{
                width: `${savedAnswers?.percentage || 0}%`,  // Ensure valid progress width, default to 0
                backgroundColor: savedAnswers?.percentage > 0 ? '#f04438' : '#ddd',  // Color based on progress
              }}
            ></div>
          </div>
          <span>{displayPercentage}</span>
          <span>{currentRiskLevel}</span>
          <div className="kebab-menu" onClick={() => setIsModalOpen(true)}>
            &#8942;
          </div>
        </div>
      </div>

      {isOpen && <div className="collapsible-content">{children}</div>}

      {/* Modal for updating progress */}
      <ProgressModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleModalSave}
        currentProgress={savedAnswers?.percentage || null}  // Ensure progress is loaded as 0 if not set
        savedComment={savedAnswers?.comment || ''}  // Load saved comment
        hasNotSureOption={notApplicable}  // Pass this prop to control checkbox visibility
        notApplicableSelected={savedAnswers?.notApplicableSelected || false} // Pass initial notApplicable state
      />
    </div>
  );
};



// CollapsibleTitle Component
const CollapsibleTitle = ({ title, children, initialPercentage, onAverageChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [averagePercentage, setAveragePercentage] = useState(initialPercentage || 0);
  const [childPercentages, setChildPercentages] = useState([]);

  // Updates the percentages for all of the child collaspible components to be ready for processing 
  const updateChildPercentage = (index, newPercentage, isNotApplicable) => {
    const updatedPercentages = [...childPercentages];
    updatedPercentages[index] = isNotApplicable ? null : parseFloat(newPercentage) || 0;
    setChildPercentages(updatedPercentages);
  };
  //Calculates the average for each the category using all of the child values
  useEffect(() => {
    // Filter out null values (representing "Not Applicable") when calculating the average
    const validPercentages = childPercentages.filter((percentage) => percentage !== null);
    if (validPercentages.length > 0) {
      const total = validPercentages.reduce((sum, percentage) => sum + percentage, 0);
      const avg = total / validPercentages.length;
      setAveragePercentage(avg);
    } else {
      setAveragePercentage(0);
    }
  }, [childPercentages]);

  useEffect(() => {
    if (children) {
      const initialPercentages = React.Children.map(children, (child) =>
        child.props.savedAnswers?.notApplicableSelected
          ? null
          : parseFloat(child.props.savedAnswers?.percentage || 0)
      );
      setChildPercentages(initialPercentages);
    }
  }, [children]);

  useEffect(() => {
    if (title && averagePercentage !== undefined) {
      onAverageChange(title, averagePercentage);
    }
  }, [averagePercentage]);

  return (
    <div className="collapsible">
      <div className="collapsible-header">
        <div className='arrow' onClick={() => setIsOpen(!isOpen)}>
          <MdOutlineCenterFocusWeak className='AIicon2'/>
          {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
          <span>{title}</span>
        </div>
        <div className="collapsible-status">
          <div className="progress-bar-container">
            <div
              className="progress-bar"
              style={{ width: `${averagePercentage}%` }}
            ></div>
          </div>
          <span>{averagePercentage.toFixed(1)}%</span>
        </div>
      </div>

      {isOpen && (
        <div className="collapsible-content">
          {React.Children.map(children, (child, index) =>
            React.cloneElement(child, {
              onPercentageChange: (newPercentage) =>
                updateChildPercentage(index, newPercentage),
            })
          )}
        </div>
      )}
    </div>

  );
};







//Copliot PopUp
const Modal = ({ isOpen, onClose, messages, isTyping, input, handleInputChange, sendMessage }) => {
  if (!isOpen) return null;

  return (
    <div className="AicontentPop">
      <div className="AIContainerBoxPop">
        <div className="AiheaderPop">
            <h1 className="AIh1">Cyber-Security Co-Pilot Advice</h1>
          </div>
        <div className="chatbot-containerPop">
          <div className="chat-messages">
            {messages.map((msg, idx) => (
              <div key={idx} className={`message ${msg.sender}`}>
                {msg.text}
              </div>
            ))}
            {isTyping && <div className="typing-indicator">Typing...</div>}
          </div>
          <div className="inputBox">
            <form onSubmit={sendMessage}>
              <input
                className="promptInput"
                type="text"
                value={input}
                onChange={handleInputChange}
                placeholder="Ask a question..."
              />
              <button className="aibutton" type="submit">
                <svg height="24" width="24" fill="#FFFFFF" viewBox="0 0 24 24" data-name="Layer 1" id="Layer_1" class="sparkle">
                  <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                </svg>
                <span className="aitext">Answer</span>
              </button>
            </form>
            <button className='closeButton' onClick={onClose}>Close</button>
          </div>
        </div>
        
      </div>
    </div>
  );
};



// Main Component
const NewAssessment = () => {

  const group1 = "Assets: People – Equip employees with know-how to be the first line of defence"
  const group2 = "Assets: Hardware and software – Know what hardware and software the organisation has and protect them"
  const group3 = "Assets: Data – Know what data the organisation has, where they are, and secure the data"
  const group4 = "Secure/Protect: Virus and malware protection – Protect from malicious software like viruses and malware"
  const group5 = "Secure/Protect: Access control – Control access to the organisation’s data and services"
  const group6 = "Secure/Protect: Secure configuration – Use secure settings for the organisation’s hardware and software"
  const group7 = "Update: Software updates – Update software on devices and systems"
  const group8 = "Backup: Back up essential data – Back up the organisation’s essential data and store them offline"
  const group9 = "Respond: Incident response – Be ready to detect, respond to, and recover from cybersecurity incidents"
  const group10 = "Respond: Business Continuity Planning - Enhance Business Resilience no matter the disruption"
  const group11 = "Respond: Disaster Recovery Planning - Rapid Recovery, Uninterrupted Progress"
  const group12 = "Respond: Business Impact Analysis - Protect the Future: Insight-Driven Business Resilience"

  const questions = [
    //Group1
    {clause: 'A.1.4(a)', text: 'The organisation shall put in place cybersecurity awareness training for all employees to ensure that employees are aware of the security practices and behaviour expected of them. Organisations may meet this requirement in different ways, e.g., provide self-learning materials for employees or engaging external training providers.', SuggestArt: 'Documented information on cybersecurity awareness training for employees.', hasNotSureOption: false, groupTitle: group1},
      { clause: 'A.1.4(b)', text: 'Cyber hygiene practices and guidelines shall be developed for employees to adopt in their daily operations.', SuggestArt: 'Documented information on cyber hygiene practices and guidelines for employees to adopt in their day-to-day operations.', hasNotSureOption: false, groupTitle: group1},
      { 
        clause: 'A.1.4(c)', 
        text: `The cyber hygiene practices and guidelines should include topics to mitigate cybersecurity incidents arising from the human factor as follows: 
          <ul>
            <li>Protect yourself from phishing;</li> 
            <li>Set strong passphrases and protect them;</li> 
            <li>Protect your corporate and/or personal devices (used for work);</li>
            <li>Report cybersecurity incidents;</li>
            <li>Handle and disclose business-critical data carefully;</li>
            <li>Work onsite and remotely in a secure manner.</li>
          </ul>`, 
        SuggestArt: 'Documented information on content of cybersecurity awareness training.', 
        hasNotSureOption: true, 
        groupTitle: group1
      },
      { 
        clause: 'A.1.4(d)',  
        text: `Where feasible, the training content should be differentiated based on the role of the employees:
          <ul>
            <li>Senior management or business leaders – e.g., developing a cybersecurity culture/mindset in the organisation or establishing a cybersecurity strategy or workplan.</li>
            <li>Employees – e.g., using strong passphrases and protecting the corporate and/or personal devices used for work.</li>
          </ul>`, 
        SuggestArt: 'Documented information on differentiation of content of cybersecurity awareness training.', 
        hasNotSureOption: true, 
        groupTitle: group1
      },
      { clause: 'A.1.4(e)', text: 'As good practice, such cybersecurity awareness initiatives should be conducted at least annually to refresh employees’ awareness.', SuggestArt: 'Documented information on frequency of cybersecurity awareness training.',  hasNotSureOption: true, groupTitle: group1},
    //Group2
    { clause: 'A.2.4(a)', text: 'An up-to-date asset inventory of all the hardware and software assets shall be maintained in the organisation. Organisations may meet this requirement in different ways, e.g., use of spreadsheet or IT asset management software to maintain the IT asset inventory.', SuggestArt: 'Asset inventory of hardware and software assets.', hasNotSureOption: false, groupTitle: group2 },
      { 
        clause: 'A.2.4(b)', 
        text: `Hardware assets within the scope of certification may include servers, network devices, laptops and computers. If the scope of the certification includes hardware assets such as mobile devices and/or IoT devices:
          <ul>
            <li>Organisations should include company-issued mobile devices as part of its asset inventory, e.g., mobile phone and tablet.</li>
            <li>Organisations should include IoT devices used within the organisation as part of its asset inventory, e.g., Closed Circuit Television (CCTV), smart printer, smart television.</li>
          </ul>`, 
        SuggestArt: 'Asset inventory of hardware and software assets.', 
        hasNotSureOption: true, 
        groupTitle: group2 
      },
      { 
        clause: 'A.2.4(c)', 
        text: `The inventory list should contain details of the hardware assets where available as follows:
          <ul>
            <li>Hardware name/model;</li>
            <li>Asset tag /serial number;</li>
            <li>Asset type;</li>
            <li>Asset location;</li>
            <li>Network address;</li>
            <li>Asset owner;</li>
            <li>Asset classification;</li>
            <li>Department;</li>
            <li>Approval/authorised date;</li>
            <li>End of Support (EOS) date</li>
          </ul>`, 
        SuggestArt: 'Asset inventory of hardware and software assets.', 
        hasNotSureOption: true, 
        groupTitle: group2 
      },
      { 
        clause: 'A.2.4(d)', 
        text: `Software assets within the scope of certification may include software applications used by the organisation. If the scope of certification includes a cloud environment:
          <ul>
            <li>Organisation shall include what is hosted on the cloud instances, e.g., software and Operating System (OS).</li>
          </ul>`, 
        SuggestArt: 'Asset inventory of hardware and software assets.', 
        hasNotSureOption: false, 
        groupTitle: group2 
      },
      { 
        clause: 'A.2.4(e)', 
        text: `The inventory list should contain the details of the software assets where available, as follows:
          <ul>
            <li>Software name;</li>
            <li>Software publisher;</li>
            <li>Software version;</li>
            <li>Business purpose;</li>
            <li>Asset classification;</li>
            <li>Approval/authorised date;</li>
            <li>EOS date.</li>
          </ul>`, 
        SuggestArt: 'Asset inventory of hardware and software assets.', 
        hasNotSureOption: true, 
        groupTitle: group2 
      },
      { clause: 'A.2.4(f)', text: 'As good practice, the hardware and software asset inventory list should be reviewed at least bi-annually (twice per year).', SuggestArt: 'Asset inventory of hardware and software assets.', hasNotSureOption: true, groupTitle: group2 },
      { clause: 'A.2.4(g)', text: 'Hardware and software assets that are unauthorised or have reached their respective EOS shall be replaced.', SuggestArt: 'Documented information of process and/or implementation of system to manage unauthorised and EOS assets.', hasNotSureOption: false, groupTitle: group2 },
      { clause: 'A.2.4(h)', text: 'In the event of any continued use of EOS assets, the organisation shall assess and understand the risk, obtain approval from senior management, and monitor it until the asset is replaced.', SuggestArt: 'Documented information of process and/or implementation of system to manage unauthorised and EOS assets.', hasNotSureOption: false, groupTitle: group2 },
      { clause: 'A.2.4(i)', text: 'An authorisation process shall be developed to onboard new hardware and software into the organisation. Organisations may meet this requirement in different ways, e.g., email approval from senior management, ensuring that new hardware and software come from official or trusted sources, performing malware scans to verify that the asset is clean and maintaining asset whitelisting/blacklisting.', SuggestArt: 'Documented information of process and/or implementation of system to manage assets.', hasNotSureOption: false, groupTitle: group2 },
      { clause: 'A.2.4(j)', text: 'The date of authorisation of software and hardware shall be keyed into the asset inventory list after obtaining the relevant dispensation, e.g., obtaining email approval or through the use of an approval form.', SuggestArt: 'Documented information of process and/or implementation of system to manage assets.', hasNotSureOption: false, groupTitle: group2 },
      { clause: 'A.2.4(k)', text: 'Software and hardware without approval dates shall be removed.', SuggestArt: 'Documented information of process and/or implementation of system to manage assets.', hasNotSureOption: false, groupTitle: group2 },
      { clause: 'A.2.4(l)', text: 'Before disposing of any hardware asset, the organisation shall ensure that all confidential information have been deleted, e.g., encrypting hard disk before reformatting and overwriting it.', SuggestArt: 'Documented information on secure asset disposal.', hasNotSureOption: false, groupTitle: group2 },
      { clause: 'A.2.4(m)', text: 'The organisation should carry out steps to ensure that the assets are disposed of securely and completely, e.g., destroy the hard disks physically or engage disk shredding services.', SuggestArt: 'Documented information on secure asset disposal.', hasNotSureOption: true, groupTitle: group2 },
    //Group 3
    {
        clause: 'A.3.4(a)',
        text: `The organisation shall identify and maintain an inventory of business-critical data in the organisation. Organisations may meet this requirement in different ways, e.g., using spreadsheet or asset inventory software. The inventory list shall contain details of the data as follows:
          <ul>
            <li>Description;</li>
            <li>Data classification and/or sensitivity;</li>
            <li>Location;</li>
            <li>Retention period.</li>
          </ul>`,
        SuggestArt: 'Asset inventory of business-critical data.',
        hasNotSureOption: false,
        groupTitle: group3
      },
    { clause:'A.3.4(b)', text: 'Review of the inventory list should be carried out at least annually, or whenever there is any change to the data captured by the organisation.', SuggestArt:'Asset inventory of business-critical data.', hasNotSureOption: true, groupTitle:  group3},
    { clause:'A.3.4(c)', text: 'The organisation shall establish a process to protect its business-critical data, e.g., password protected documents, encryption of personal data (at rest) and/or emails.', SuggestArt:'Documented information of process to protect business-critical data.', hasNotSureOption: false, groupTitle:  group3},
    { clause:'A.3.4(d)', text: 'There shall also be measures in place to prevent the employees from leaking confidential and/or sensitive data outside of the organisation, e.g., disabling USB ports.', SuggestArt:'Documented information and/or implementation of data leakage and disposal process.', hasNotSureOption: false, groupTitle:  group3},
    { clause:'A.3.4(e)', text: 'Before disposing of any paper-based (hard copy) media, the organisation shall carry out steps to ensure that those containing confidential and/or sensitive data have been securely shredded.', SuggestArt:'Documented information and/or implementation of data leakage and disposal process.', hasNotSureOption: false, groupTitle:  group3},
    //Group 4
    { clause:'A.4.4(a)', text: 'Anti-malware solutions shall be used and installed in endpoints to detect attacks on the organisation’s environment. Examples of endpoints include laptop computers, desktop computers, servers and virtual environments.', SuggestArt:'Documented information outlining functionality and implementation of on anti-virus/anti-malware solution.', hasNotSureOption: false, groupTitle:  group4},
    { clause:'A.4.4(b)', text: 'Virus and malware scans shall be carried out to detect possible cyberattacks. Where feasible, scans should always be automated and remain active to provide constant protection.', SuggestArt:'Documented information on configuration of anti-virus/anti-malware solution.', hasNotSureOption: false, groupTitle:  group4},
    { clause:'A.4.4(c)', text: 'Organisations shall enable auto-updates or configure the anti-malware solution to update signature files or equivalent (e.g., non-signature based machine learning solutions) to detect new malware. Where possible, these updates should take place at least daily to stay protected from the latest malware.', SuggestArt:'Documented information on configuration of anti-virus/anti-malware solution.', hasNotSureOption: false, groupTitle:  group4},
    { clause:'A.4.4(d)', text: 'Anti-malware solution shall be configured to automatically scan the files upon access. This includes files and attachments downloaded from the Internet through the web browser or email and external sources such as from portable USB drives.', SuggestArt:'Documented information on configuration of anti-virus/anti-malware solution.', hasNotSureOption: false, groupTitle:  group4},
    {
        clause: 'A.4.4(e)',
        text: `If the scope of certification includes mobile devices, IoT devices, cloud environment or use of web browser/email:
          <ul>
            <li>Mobile devices: Anti-malware solution should be installed and running on mobile devices.</li>
            <li>IoT devices: Anti-malware solution should be integrated with the IoT devices, e.g., CCTV, smart television, smart printers, digital door lock.</li>
            <li>Cloud: Anti-malware solution should be deployed on the cloud platform.</li>
            <li>Web browser/email:
              <ul>
                <li>Only fully supported web browsers and email client software with security controls should be used.</li>
                <li>Anti-phishing and spam filtering tools should be established for the web browser/email client software.</li>
                <li>Web browsers and/or email plug-ins/extensions/add-ons that are not necessary should be disabled and/or removed.</li>
                <li>Web filtering should be deployed to protect the business from malicious sites, where feasible.</li>
              </ul>
            </li>
          </ul>`,
        SuggestArt: 'Documented information outlining functionality and implementation of anti-virus/anti-malware solution.',
        hasNotSureOption: true,
        groupTitle: group4
      },
    { clause:'A.4.4(g)', text: 'In an environment where there are endpoints connecting to the Internet and/or cloud-based applications, a software firewall (host-based firewall) should be configured and switched on for all the endpoints in the organisation where available, e.g., turning on the built-in software firewall feature included in most operating systems or anti-malware solutions.', SuggestArt:'Network and/or systems diagram. Documented information on configuration of  firewall.', hasNotSureOption: true, groupTitle:  group4},
    { clause:'A.4.4(h)', text: 'As good practice, firewall configurations and rules should ideally be reviewed and verified annually to protect the organisation’s Internet-facing assets where applicable.', SuggestArt:'Documented information on review of firewall rules and configuration.', hasNotSureOption: true, groupTitle:  group4},
    {
        clause: 'A.4.4(i)',
        text: `If the scope of certification includes mobile and/or IoT devices:
          <ul>
            <li>Mobile devices: It is recommended that firewalls should be installed and enabled on employees’ mobile devices.</li>
            <li>IoT devices: It is recommended that firewalls should be configured and enabled on IoT devices where possible.</li>
          </ul>`,
        SuggestArt: 'Documented information outlining functionality and implementation of firewall solution.',
        hasNotSureOption: true,
        groupTitle: group4
      },
    { clause:'A.4.4(j)', text: 'The organisation shall ensure that its employees install/access only authorised software/attachments within the organisation from official or trusted sources.',SuggestArt:'Documented information on installation and access to authorised software/attachments.', hasNotSureOption: false, groupTitle:  group4},
    { clause:'A.4.4(k)', text: 'The organisation shall ensure that employees are aware of the use of trusted network connections for accessing the organisation’s data or business email, e.g., mobile hotspot, personal Wi-Fi, corporate Wi-Fi and Virtual Private Network (VPN).', SuggestArt:'Content of cybersecurity awareness training to employees. Cybersecurity policies and practices for employees.', hasNotSureOption: false, groupTitle:  group4},
    { clause:'A.4.4(l)', text: 'The organisation shall ensure that its employees are aware of the need to report any suspicious email or attachment to the IT team and/or senior management immediately.', SuggestArt:'Content of cybersecurity awareness training to employees. Cybersecurity policies and practices for employees.', hasNotSureOption: false, groupTitle:  group4},
    // Group 5
    { clause:'A.5.4(a)', text: 'Account management shall be established to maintain and manage the inventory of accounts. The organisation may meet this in different ways, e.g., using of spreadsheets or exporting the list from software directory services.', SuggestArt:'Inventory of user accounts. List of employees in the organisation, period of employment, and their functional roles.', hasNotSureOption: false, groupTitle:  group5},
    {
        clause: 'A.5.4(b)',
        text: `The account inventory list shall contain details for user, administrator, third-party, and service accounts not limited to the following:
            <ul>
            <li>Name;</li>
            <li>Username;</li>
            <li>Department;</li>
            <li>Role/account type;</li>
            <li>Date of access created;</li>
            <li>Last logon date.</li>
            </ul>`,
        SuggestArt: 'Inventory of user accounts. List of employees in the organisation, period of employment, and their functional roles.',
        hasNotSureOption: false,
        groupTitle: group5
    },
    {
        clause: 'A.5.4(c)',
        text: `The organisation shall have a process with the necessary approvals to grant and revoke access. The organisation may implement this in different ways, e.g., email approval or access request form. This shall be implemented when there are personnel changes such as onboarding of new staff or change of role(s) for employees. The following fields shall be captured as follows:
          <ul>
            <li>Name;</li>
            <li>System to access;</li>
            <li>Department;</li>
            <li>Role/account type;</li>
            <li>From date;</li>
            <li>To date.</li>
          </ul>`,
        SuggestArt: 'Documented information on process to request to grant and revoke access.',
        hasNotSureOption: false,
        groupTitle: group5
    },
    { clause:'A.5.4(d)', text: 'Access shall be managed to ensure employees can access only the information and systems required for their job role.', SuggestArt:'Inventory of user accounts. List of employees in the organisation, period of employment, and their functional roles.', hasNotSureOption: false, groupTitle:  group5},
    { clause:'A.5.4(e)', text: 'Accounts with access rights that are no longer required or have exceeded the requested date shall have their access disabled or removed from the system. Shared, duplicate, obsolete and invalid accounts shall be removed.', SuggestArt:'Inventory of user accounts. List of employees in the organisation, period of employment, and their functional roles.', hasNotSureOption: false, groupTitle:  group5},
    { clause:'A.5.4(f)', text: 'The administrator account shall only be accessed to perform administrator functions with approval from the senior management.', SuggestArt:'Documented information on process to request for administrative access to system.', hasNotSureOption: false, groupTitle:  group5},
    { clause:'A.5.4(g)', text: 'Access shall be managed to ensure that third parties or contractors can access only the information and systems required for their job role. Such access shall be removed once they no longer require them.', SuggestArt:'Inventory of user accounts. List of third parties/contractors supporting the organisational, period of support, and their functional roles.', hasNotSureOption: false, groupTitle:  group5},
    { clause:'A.5.4(h)', text: 'Third parties or contractors working with sensitive information in the organisation shall sign a non-disclosure agreement form. The form should include disciplinary action(s) for failure to abide by the agreement.', SuggestArt:'List of third parties/contractors supporting the organisational, period of support, and their functional roles. Non-disclosure agreement signed by third-parties and contractors.', hasNotSureOption: false, groupTitle:  group5},
    { clause:'A.5.4(i)', text: 'Physical access control shall be enforced to allow only authorised employees/contractors to access the organisation’s IT assets and/or environment, e.g., use of cable lock to lock the workstations and card access door lock to authenticate and authorise entry.', SuggestArt:'Implementation of physical access control to organisation IT assets and/or environment. Documented information on process to request to grant and revoke physical  access.', hasNotSureOption: false, groupTitle:  group5},
    { clause:'A.5.4(j)', text: 'As good practice, account reviews should be carried out at least quarterly or whenever there are changes to the account list, e.g., during onboarding and offboarding processes or organisation restructuring.', SuggestArt:'Documented information on the process to conduct account review. E.g. practices to grant and revoke access during employee onboard and offboarding.', hasNotSureOption: true, groupTitle:  group5},
    { clause:'A.5.4(k)', text: 'Dormant or inactive accounts which have been inactive for a prolonged period, e.g., sixty (60) days should be removed or disabled.', SuggestArt:'Inventory of user accounts.', hasNotSureOption: true, groupTitle:  group5},
    { clause:'A.5.4(l)', text: 'The organisation shall change all default passwords and replace them with a strong passphrase, e.g., it should be at least twelve (12) characters long and include upper case, lower case, and/or special characters.', SuggestArt:'Documented information on password or passphrase policy.', hasNotSureOption: false, groupTitle:  group5},
    { clause:'A.5.4(m)', text: 'User accounts shall be disabled and/or locked out after multiple failed login attempts, e.g., after ten (10) failed login attempts, ’throttling’ the rate of attempts .', SuggestArt:'Documented information on policy for user accounts.', hasNotSureOption: false, groupTitle:  group5},
    { clause:'A.5.4(n)', text: 'The account password shall be changed in the event of any suspected compromise.', SuggestArt:'Documented information on password or passphrase policy.', hasNotSureOption: false, groupTitle:  group5},
    { clause:'A.5.4(o)', text: 'Where feasible, two-factor authentication (2FA) should be used for administrative access to important systems, such as an Internet- facing system containing sensitive or business-critical data. Organisations may implement this in different ways, e.g., use of an authenticator application on the mobile or one-time password (OTP) token.', SuggestArt:'Documented information outlining functionality and implementation of two-factor authentication (2FA).', hasNotSureOption: true, groupTitle:  group5},
    { clause:'A.5.4(p)', text: 'Where feasible, trusted software to manage passphrases should be used to aide employee passphrase management.', SuggestArt:'Documented information outlining functionality and implementation of passphrase management solution.', hasNotSureOption: true, groupTitle:  group5},
    //Group 6
    { clause:'A.6.4(a)', text: 'Security configurations shall be enforced for the assets including desktop computers, servers and routers. Organisations may meet this requirement in different ways, e.g., adopting industry recommendations and standards such as Center for Internet Security (CIS) benchmarks on configuration guidelines across multiple vendor products, running baseline security analyser and/or using system configuration scripts.', SuggestArt:'Documented information and/or implementation of configuration of assets. Network and/or systems diagram.', hasNotSureOption: false, groupTitle:  group6},
    { clause:'A.6.4(b)', text: 'Weak or default configurations shall be avoided or updated before using them, e.g., changing default password and performing deep scanning with anti-malware solution instead of standard scan.', SuggestArt:'Documented information and/or implementation of configuration of assets. Network and/or systems diagram.', hasNotSureOption: false, groupTitle:  group6},
    { clause:'A.6.4(c)', text: 'Insecure configurations and weak protocols shall be replaced or upgraded to address the associated vulnerabilities, e.g., using Hypertext Transfer Protocol Secure (HTTPS) over normal Hypertext Transfer Protocol (HTTP) to encrypt data communication and upgrading Wired Equivalent Privacy (WEP) to Wi-Fi Protected Access 2/3 (WPA2/WPA3) to enhance the Wi-Fi security standards.', SuggestArt:'Documented information and/or implementation of configuration of assets. Network and/or systems diagram.', hasNotSureOption: false, groupTitle:  group6},
    { clause:'A.6.4(d)', text: 'Features, services, or applications that are not in used shall be disabled or removed, e.g., disabling file sharing services, software macros and File Transfer Protocol (FTP) ports. ', SuggestArt:'Documented information and/or implementation of configuration of assets. Network and/or systems diagram.', hasNotSureOption: false, groupTitle:  group6},
    { clause:'A.6.4(e)', text: 'Automatic connection to open networks and auto-run feature of non-essential programs (other than backup or anti-malware solution, etc.) shall be disabled.', SuggestArt:'Documented information and/or implementation of configuration of assets. Network and/or systems diagram.', hasNotSureOption: false, groupTitle:  group6},
    { clause:'A.6.4(f)', text: 'Logging should also be enabled for software and hardware assets where feasible, e.g., system, events and security logs.', SuggestArt:'Documented information on logging of software and hardware assets.', hasNotSureOption: true, groupTitle:  group6},
    { clause:'A.6.4(g)', text: 'As good practice, automatic lock/session log out should be enabled after fifteen (15) min of inactivity for the organisation’s assets. These include user sessions on the laptop computer, server, non-mobile device, database, and administrator portal.', SuggestArt:'Documented information and/or implementation of configuration of assets.', hasNotSureOption: true, groupTitle:  group6},
    {
        clause: 'A.6.4(h)',
        text: `If the scope of certification includes mobile devices, IoT devices, and/or cloud environment:
          <ul>
            <li>Mobile devices – e.g., mobile phone, tablet:
              <ul>
                <li>Mobile devices should not be jail-broken or rooted.</li>
                <li>Mobile device passcodes should be enabled.</li>
                <li>Automatic mobile device locks should be activated after two (2) min of inactivity.</li>
                <li>Mobile applications should only be downloaded from official or trusted sources.</li>
              </ul>
            </li>
            <li>IoT devices:
              <ul>
                <li>Network hosting the IoT devices should be separated from the network containing the organisation’s assets and data.</li>
                <li>Security features should be enabled on IoT devices, e.g., turning off device auto-discovery and Universal Plug and Play (UPnP).</li>
                <li>In selecting IoT devices, the organisation should use devices rated by the Cybersecurity Labelling Scheme (CLS) (where available).</li>
              </ul>
            </li>
            <li>Cloud:
              <ul>
                <li>Security logging and monitoring should be turned on for cloud visibility, e.g., history of Application Programming Interface (API) calls, change tracking and compliance.</li>
              </ul>
            </li>
          </ul>`,
        SuggestArt: 'Documented information and/or implementation of configuration of assets.',
        hasNotSureOption: true,
        groupTitle: group6
    },
    //Group 7
    { clause:'A.7.4(a)', text: 'The organisation shall prioritise the implementation of critical or important updates for operating systems and applications (e.g., security patches) to be applied as soon as possible.', SuggestArt:'Documented information and/or implementation of software updates and patches.', hasNotSureOption: false, groupTitle:  group7},
    { clause:'A.7.4(b)', text: 'The organisation should carry out compatibility tests on updates for operating system and applications before installing them.', SuggestArt:'Documented information and/or implementation of compatibility tests on updates for operating system and applications.', hasNotSureOption: true, groupTitle:  group7},
    { clause:'A.7.4(c)', text: 'The organisation should consider enabling automatic updates for critical operating system and application patches where feasible so that they can receive the latest updates.', SuggestArt:'Documented information and/or implementation of software updates and patches.', hasNotSureOption: true, groupTitle:  group7},
    {
        clause: 'A.7.4(d)',
        text: `If the scope of certification includes mobile devices, IoT devices, and/or cloud environment:
          <ul>
            <li>Mobile devices – e.g., mobile phone, tablet:
              <ul>
                <li>The organisation should ensure that updates and patches for mobile devices are only downloaded from trusted sources (e.g., official app store from the manufacturer).</li>
              </ul>
            </li>
            <li>IoT devices:
              <ul>
                <li>The organisation should remove or replace any IoT devices (e.g., CCTV, printers) that are not receiving any software patches or updates.</li>
              </ul>
            </li>
            <li>Cloud:
              <ul>
                <li>The organisation should refer to the cloud shared responsibility model with its Cloud Service Provider (CSP). This will allow organisations to be aware of when the organisation is responsible for software updates and security patches, and when the CSP is responsible.</li>
                <li>The organisation should have visibility on the software updates and security patches done by its CSPs.</li>
                <li>The organisation should also have security requirements regarding software updates defined for its CSPs.</li>
              </ul>
            </li>
          </ul>`,
        SuggestArt: 'Documented information on software updates and patches.',
        hasNotSureOption: true,
        groupTitle: group7
    },
    //Group 8
    { clause:'A.8.4(a)', text: 'The organisation shall identify business-critical systems and those containing essential business information and perform backup. What needs to be backed up is guided by identifying what is needed for business recovery in the event of a cybersecurity incident. Examples of business-critical systems include stock-trading system, railway operating and control system. Examples of essential business information include financial data and business transactions.', SuggestArt:'Documented information and/or implementation of systems backup.', hasNotSureOption: false, groupTitle:  group8},
    { clause:'A.8.4(b)', text: 'The backups shall be performed on a regular basis, with the backup frequency aligned to the business requirements and how many days’ worth of data the organisation can afford to lose.', SuggestArt:'Documented information of systems backup. Records of backups.', hasNotSureOption: false, groupTitle:  group8},
    { clause:'A.8.4(c)', text: 'For non-business-critical systems or non-essential information, the backups should still be performed but at/on a lower frequency/long term basis.', SuggestArt:'Documented information and/or implementation of systems backup.', hasNotSureOption: true, groupTitle:  group8},
    { clause:'A.8.4(d)', text: 'The backup process should be automated where feasible.', SuggestArt:'Documented information and/or implementation of systems backup.', hasNotSureOption: true, groupTitle:  group8},
    {
        clause: 'A.8.4(e)',
        text: `If the scope of certification includes cloud environment:
          <ul>
            <li>The organisation shall understand the role and responsibility between itself and the CSP in terms of data backup, e.g., cloud shared responsibility model, scope, and coverage of the cloud service.</li>
            <li>Data backup shall be carried out by the organisation, e.g., storing the backups in a hard disk drive, purchasing the backup services by the CSP, and adopting multiple clouds to be used as backups.</li>
          </ul>`,
        SuggestArt: 'Documented information and/or implementation of systems backup.',
        hasNotSureOption: false,
        groupTitle: group8
    },
    {
        clause: 'A.8.4(f)',
        text: `If the scope of certification includes hardware assets such as mobile devices and/or IoT devices:
          <ul>
            <li>Mobile devices:
              <ul>
                <li>Essential business information stored in mobile phones should be auto backed up and transferred to a secondary mobile phone or secondary storage for backup, e.g., SMS conversations or contact of an important client.</li>
              </ul>
            </li>
            <li>IoT devices:
              <ul>
                <li>IoT devices containing the organisation’s essential information should be backed up manually where automatic backup is not available, e.g., sensors in farms to improve operational safety and efficiency and in healthcare to monitor patients with greater precision to provide timely treatment.</li>
              </ul>
            </li>
          </ul>`,
        SuggestArt: 'Documented information and/or implementation of systems backup.',
        hasNotSureOption: true,
        groupTitle: group8
    },
    { clause:'A.8.4(g)', text: 'All backups shall be protected from unauthorised access and be restricted to authorised personnel only. Backups should minimally be password-protected.', SuggestArt:'Documented information and/or implementation of systems backup.', hasNotSureOption: false, groupTitle:  group8},
    { clause:'A.8.4(h)', text: 'Backups shall be stored separately (i.e., offline) from the operating environment. Where feasible, backups should be stored offsite, e.g., separate physical location.', SuggestArt:'Documented information and/or implementation of systems backup.', hasNotSureOption: false, groupTitle:  group8},
    { clause:'A.8.4(i)', text: 'Frequent backups such as daily or weekly backups should be stored online to facilitate quick recovery, e.g., cloud backup storage.', SuggestArt:'Documented information and/or implementation of systems backup.', hasNotSureOption: true, groupTitle:  group8},
    { clause:'A.8.4(j)', text: 'Longer term backups such as monthly backups shall be stored offline in an external secure storage location, e.g., password-protected USB flash drives, encrypted external hard disks and/or tape storage at an alternative office location.', SuggestArt:'Documented information and/or implementation of systems backup.', hasNotSureOption: false, groupTitle:  group8},
    { clause:'A.8.4(k)', text: 'As good practice, backups should be tested at least bi-annually, or more frequently, to ensure that business-critical systems and essential business information can be restored effectively.', SuggestArt:'Documented information and/or implementation of testing of systems backup.', hasNotSureOption: true, groupTitle:  group8},
    //Group 9
    { clause:'A.9.4(a)', text: `The organisation shall establish an up-to-date basic incident response plan to guide the organisation on how to respond to common cybersecurity incidents. Examples include phishing, data breach, ransomware. The plan shall contain details as follows:
    – Clear roles and responsibilities of key personnel in the organisation involved in the incident response plan process.
    – Procedures to detect, respond, and recover from the common cybersecurity threat scenarios, e.g., phishing, ransomware, data breach.
    – Communication plan and timeline to escalate and report the incident to internal and external stakeholders (such as regulators, customers, and senior management).`, SuggestArt:'Incident response plan with guidance on how to respond to common cyber incidents.', hasNotSureOption: false, groupTitle:  group9},
    { clause:'A.9.4(b)', text: 'The incident response plan shall be made aware to all employees in the organisation that have access to the organisation’s IT assets and/or environment.', SuggestArt:'Incident response plan with guidance on how to respond to common cyber incidents.', hasNotSureOption: false, groupTitle:  group9},
    { clause:'A.9.4(c)', text: 'The organisation should conduct post- incident review and incorporate learning points to strengthen and improve the incident response plan.', SuggestArt:'Incident response plan with  post-incident review, improvement of plan and review history.', hasNotSureOption: true, groupTitle:  group9},
    { clause:'A.9.4(d)', text: 'As good practice, the incident response plan should be reviewed at least annually.', SuggestArt:'Incident response plan with  post-incident review, improvement of plan and review history.', hasNotSureOption: true, groupTitle:  group9},
    // Group 10
    { clause:'A.10.4(a)', text: 'Strategy Alignment- Is the business continuity strategy aligned with the overall business objectives and risk management framework of the organisation?', groupTitle:  group10},
    { clause:'A.10.4(b)', text: 'Plan Documentation- Are business continuity plans and procedures well-documented, up-to-date, and easily accessible to relevant stakeholders?', groupTitle: group10},
    { clause:'A.10.4(c)', text: 'Resource Allocation- Has the enterprise allocated sufficient resources (financial, human, technological) to implement and maintain the business continuity strategy effectively?', groupTitle:  group10},
    { clause:'A.10.4(d)', text: 'Testing and Training- How frequently are business continuity plans tested through simulations or drills, and are employees adequately trained to respond to various scenarios?', groupTitle:  group10},
    { clause:'A.10.4(e)', text: 'Vendor and Supply Chain Management- How does the enterprise ensure that key vendors and partners have robust business continuity plans in place to minimise disruptions to the supply chain?', groupTitle:  group10},
    { clause:'A.10.4(f)', text: 'Communication Protocols- Are there clear communication protocols established to disseminate information and instructions during a crisis or emergency situation?', groupTitle:  group10},
    { clause:'A.10.4(g)', text: 'Continuous Improvement- Does the enterprise conduct regular reviews and evaluations of its business continuity strategy to identify areas for improvement and incorporate lessons learned from past incidents?', groupTitle:  group10},
    // Group 11
    { clause:'A.11.4(a)', text: "Risk Assessment and Analysis: Conducting a comprehensive risk assessment to identify potential threats, vulnerabilities, and risks to the organization's IT infrastructure, systems, and data. This includes assessing natural disasters, such as earthquakes or floods, as well as human-made disasters like cyberattacks or data breaches.", groupTitle: group11},
    { clause:'A.11.4(b)', text: 'Business Impact Analysis (BIA): Performing a BIA to prioritize critical business processes, applications, and data that must be recovered in the event of a disaster. This helps determine recovery time objectives (RTOs) and recovery point objectives (RPOs) for each critical function or system.', groupTitle: group11},
    { clause:'A.11.4(c)', text: 'Backup and Data Protection: Implementing regular backups and data protection measures to ensure the integrity and availability of critical data. This may include both onsite and offsite backups, data replication, encryption, and other data protection mechanisms to prevent data loss or corruption during a disaster.', groupTitle: group11},
    { clause:'A.11.4(d)', text: "Disaster Recovery Strategies: Developing and implementing disaster recovery strategies to recover IT systems, applications, and data in the event of a disaster. This may involve strategies such as cold, warm, or hot site recovery, cloud-based disaster recovery, or hybrid approaches depending on the organization's requirements and budget.", groupTitle: group11},
    { clause:'A.11.4(e)', text: 'Emergency Response Procedures: Establishing emergency response procedures and protocols to ensure a swift and coordinated response to disasters. This includes defining roles and responsibilities, establishing communication channels, and conducting regular drills and exercises to test the effectiveness of the response plan.', groupTitle: group11},
    { clause:'A.11.4(f)', text: 'Infrastructure and Technology Resilience: Implementing resilient infrastructure and technology solutions to minimize downtime and ensure the continuity of critical operations during a disaster. This may involve redundant systems, failover mechanisms, high availability configurations, and other resilience measures.', groupTitle: group11},
    { clause:'A.11.4(g)', text: 'Incident Management and Reporting: Establishing incident management procedures for reporting, escalating, and resolving disasters or incidents. This includes establishing incident response teams, defining communication protocols, and documenting incident response procedures to ensure a coordinated and effective response.', groupTitle: group11},
    { clause:'A.11.4(h)', text: 'Training and Awareness: Providing training and awareness programs to educate employees, stakeholders, and key personnel about disaster recovery procedures, roles, and responsibilities. This helps ensure that everyone understands their roles and can effectively respond to a disaster when needed.', groupTitle: group11},
    { clause:'A.11.4(i)', text: 'Testing and Maintenance: Conducting regular testing, validation, and maintenance of the disaster recovery plan to ensure its effectiveness and reliability. This includes testing backup and recovery procedures, conducting tabletop exercises, and reviewing and updating the plan regularly to address changing threats and requirements.', groupTitle: group11},
    { clause:'A.11.4(j)', text: 'Compliance and Governance: Ensuring compliance with relevant regulations, industry standards, and best practices related to disaster recovery and business continuity. This may include regulatory requirements for data protection, privacy, and security, as well as industry standards such as ISO 22301 or NIST SP 800-34.', groupTitle: group11},
    // Group 12
    { clause:'A.12.4(a)', text: "Identifying Critical Business Functions: Identify and prioritize the critical business functions, processes, applications, and resources that are essential for the organization's operations. These may include customer service, financial transactions, supply chain management, IT systems, and other key business processes.", groupTitle: group12},
    { clause:'A.12.4(b)', text: 'Determining Recovery Time Objectives (RTOs): Define the maximum acceptable downtime for each critical business function. RTOs represent the time within which a business process or system must be restored after a disruption to avoid significant financial or operational impacts. RTOs are typically defined in terms of hours, days, or weeks.', groupTitle: group12},
    { clause:'A.12.4(c)', text: 'Estimating Recovery Point Objectives (RPOs): Determine the maximum acceptable data loss for each critical business function. RPOs represent the acceptable amount of data loss that can occur during a disruption before it becomes detrimental to the organization. RPOs are typically defined in terms of time intervals, such as minutes, hours, or days.', groupTitle: group12},
    { clause:'A.12.4(d)', text: "Assessing Dependencies and Interdependencies: Identify the dependencies and interdependencies between critical business functions, processes, applications, and resources. This includes understanding the relationships between different systems, data flows, dependencies on third-party vendors or suppliers, and other dependencies that may impact the organization's ability to recover from a disruption.", groupTitle: group12},
    { clause:'A.12.4(e)', text: 'Quantifying Impacts and Losses: Quantify the potential impacts and losses associated with disruptions to critical business functions. This may include financial impacts, such as revenue loss, increased costs, penalties, and regulatory fines, as well as non-financial impacts, such as damage to reputation, customer dissatisfaction, and legal liabilities.', groupTitle: group12},
    { clause:'A.12.4(f)', text: 'Identifying Resource Requirements: Determine the resources, including personnel, equipment, facilities, and technology, required to recover each critical business function within the defined RTOs and RPOs. This involves identifying the necessary resources for business continuity planning, disaster recovery, and crisis management.', groupTitle: group12},
    { clause:'A.12.4(g)', text: 'Risk Assessment and Prioritization: Assess the risks associated with disruptions to critical business functions and prioritize them based on their likelihood and potential impact. This involves analyzing the probability of different types of disruptions, such as natural disasters, cyberattacks, equipment failures, and human errors, and prioritizing mitigation efforts accordingly.', groupTitle: group12},
    { clause:'A.12.4(h)', text: "Documenting Findings and Recommendations: Document the findings of the BIA process, including critical business functions, RTOs, RPOs, dependencies, impacts, resource requirements, and risk assessments. Provide recommendations for mitigating risks, improving resilience, and enhancing the organization's ability to recover from disruptions.", groupTitle: group12},
    { clause:'A.12.4(i)', text: "Review and Update: Regularly review and update the BIA findings and recommendations to reflect changes in the organization's business environment, operations, technologies, and risks. Business impact analysis is an iterative process that should be periodically revisited to ensure its relevance and effectiveness.", groupTitle: group12},

  ];

  
  const [answers, setAnswers] = useState({});
  const [groupAverages, setGroupAverages] = useState({});
  const [sectorName, setSectorName] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [sectorLoaded, setSectorLoaded] = useState(false);
  const [isSixTrue, setIsSixTrue] = useState(null)

 

  
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      loadUserAnswersFromFirebase(user.uid).then((loadedAnswers) => {
        console.log("Loaded answers from Firebase:", loadedAnswers);  // Debugging: log loaded answers
        setAnswers(loadedAnswers || {}); // Load saved answers or use an empty object
        console.log("Answers state after setting:", loadedAnswers || {});
      });
    }
  }, []);

  const fetchIsSixTrue = async () => {
    try {
        const userId = getUserId();
        console.log('User ID:', userId); // Add this line for debugging

        const userDocQuery = query(collection(db, 'users'), where('userId', '==', userId));
        const userDocSnapshot = await getDocs(userDocQuery);

        if (!userDocSnapshot.empty) {
            const userDocRef = userDocSnapshot.docs[0].ref;
            const userDocData = userDocSnapshot.docs[0].data();
            const getIsSixTrue = userDocData.isSixteenComplete || null;
            setIsSixTrue(getIsSixTrue)
            
        } else {
            console.log('User document not found for user ID:', userId);
        } 
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  

  useEffect(() => {
    //Fetches the users sector 
    const getUserSector = async () => {
      try {
        const userId = getUserId();
        console.log('User ID:', userId);
        const userDocQuery = query(collection(db, 'users'), where('userId', '==', userId));
        const userDocSnapshot = await getDocs(userDocQuery);

        if (!userDocSnapshot.empty) {
          const userDocData = userDocSnapshot.docs[0].data();
          const getSectorName = userDocData.sector || null;
          setSectorName(getSectorName);
          console.log('Sector Name:', getSectorName);
          // Mark sector as loaded
          setSectorLoaded(true);
        } else {
          console.log('User document not found for user ID:', userId);
        }
       
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getUserSector();
  }, []);

  useEffect(() => {
    fetchIsSixTrue();
  }, []);

   
  //Autosave Function every 1 second also calulating the averages for cyber risk score 
  useEffect(() => {
    if (sectorLoaded) {
      const autoSaveInterval = setInterval(async () => {
        const user = auth.currentUser;
        if (user) {
          try {
            await saveAnswersToFirebase(answers, user.uid);
            
            // Array of first 9 groups
            const first9Groups = [
              "Assets: People – Equip employees with know-how to be the first line of defence",
              "Assets: Hardware and software – Know what hardware and software the organisation has and protect them",
              "Assets: Data – Know what data the organisation has, where they are, and secure the data",
              "Secure/Protect: Virus and malware protection – Protect from malicious software like viruses and malware",
              "Secure/Protect: Access control – Control access to the organisation’s data and services",
              "Secure/Protect: Secure configuration – Use secure settings for the organisation’s hardware and software",
              "Update: Software updates – Update software on devices and systems",
              "Backup: Back up essential data – Back up the organisation’s essential data and store them offline",
              "Respond: Incident response – Be ready to detect, respond to, and recover from cybersecurity incidents"
            ];

            const sectorRiskAdjustments = {
              "Manufacturing": 5,
              "Finance and insurance": 4,
              "Professional, business, and consumer services": 3,
              "Energy": 3,
              "Retail and wholesale": 2,
              "Education": 2,
              "Healthcare": 2,
              "Government": 1,
              "Transportation": 1,
              "Media and telecom": 0,
            };

            const groupRiskBands = {
              "Assets: People – Equip employees with know-how to be the first line of defence": [14, 12, 10, 7, 4, 2],
              "Assets: Hardware and software – Know what hardware and software the organisation has and protect them": [9, 7, 7, 5, 2, 0.5],
              "Assets: Data – Know what data the organisation has, where they are, and secure the data": [14, 12, 10, 7, 4, 1],
              "Secure/Protect: Virus and malware protection – Protect from malicious software like viruses and malware": [10, 8, 6, 4, 2, 0.5],
              "Secure/Protect: Access control – Control access to the organisation’s data and services": [20, 18, 15, 12, 9, 5],
              "Secure/Protect: Secure configuration – Use secure settings for the organisation’s hardware and software": [10, 8, 6, 4, 2, 0.5],
              "Update: Software updates – Update software on devices and systems": [9, 7, 5, 3, 1, 0],
              "Backup: Back up essential data – Back up the organisation’s essential data and store them offline": [5, 4, 3, 2, 1, 0],
              "Respond: Incident response – Be ready to detect, respond to, and recover from cybersecurity incidents": [9, 8, 6, 4, 2, 0.5],
            };

            // Calculate group averages for all groups
            const groupKeys = Object.keys(groupedClauses);
            const newGroupAverages = {};
            let totalRiskScore = 0;

            console.log("Starting group average calculations...");
            
            for (const groupTitle of groupKeys) {
              const clauses = groupedClauses[groupTitle];
              console.log(`Calculating for group: ${groupTitle}`);

              // Calculate valid percentages
              const validPercentages = clauses
                .map(({ clause, text }) => {
                  const clauseAndSummaryText = `${clause} - ${text.split(' ').slice(0, 6).join(' ')}...`;
                  const answer = answers[groupTitle]?.[clauseAndSummaryText] || {};
                  const percentage = answer.percentage || 0;
                  const notApplicable = answer.notApplicableSelected || false;
                  console.log(`Clause: ${clauseAndSummaryText}, Percentage: ${percentage}, NotApplicable: ${notApplicable}`);
                  return !notApplicable ? percentage : null;
                })
                .filter(percentage => percentage !== null);

              const groupAverage = validPercentages.length > 0
                ? validPercentages.reduce((sum, percentage) => sum + percentage, 0) / validPercentages.length
                : 0;

              newGroupAverages[groupTitle] = groupAverage;
              console.log(`Group: ${groupTitle}, Average: ${groupAverage}`);

              // Calculate risk score only for the first 9 groups
              if (first9Groups.includes(groupTitle)) {
                const groupRiskScores = groupRiskBands[groupTitle];
                
                if (!groupRiskScores) {
                  console.error(`Risk band not found for group: ${groupTitle}`);
                  continue; // Skip this group if no risk band is defined
                }

                let riskScore = 0;

                if (groupAverage <= 20) {
                  riskScore = groupRiskScores[0];
                } else if (groupAverage <= 40) {
                  riskScore = groupRiskScores[1];
                } else if (groupAverage <= 60) {
                  riskScore = groupRiskScores[2];
                } else if (groupAverage <= 80) {
                  riskScore = groupRiskScores[3];
                } else if (groupAverage <= 90) {
                  riskScore = groupRiskScores[4];
                } else if (groupAverage <= 100) {
                  riskScore = groupRiskScores[5];
                }

                console.log(`Group: ${groupTitle}, Risk Score: ${riskScore}`);
                totalRiskScore += riskScore;
              }
            }

            console.log("Total Risk Score from first 9 groups:", totalRiskScore);

            // Apply sector-based adjustment if needed
            if (totalRiskScore < 80) {
              try { 
                const sectorAdjustment = sectorRiskAdjustments[sectorName] || 0;
                totalRiskScore += sectorAdjustment;
                console.log(`Applying sector adjustment of ${sectorAdjustment} for sector: ${sectorName}`);
              } catch (error) {
                console.error('Error fetching user sector:', error);
              }
            }
            const userDocRef = doc(db, 'Answers', user.uid);
            await updateDoc(userDocRef, {
              groupAverages: newGroupAverages,
              CyberRiskScore: totalRiskScore,
            });

            console.log("Final Cyber Risk Score:", totalRiskScore);

            // Check if all questions are answered and update Firebase
            const allAnswered = await checkAllQuestionsAnswered();
            if (allAnswered) {
              console.log("All questions are answered. Updated Firebase with isAssessmentComplete: true");
            }

          } catch (error) {
            console.error('Error in auto-save:', error);
          }
        }
      }, 1000);
      return () => clearInterval(autoSaveInterval);
    } 
  }, [sectorLoaded,answers]);
  //Not allow scrolling when copilot is open 
  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll'); // Cleanup on unmount
    };
  }, [isModalOpen]);

  
    
  
  //When a user changes a score the averages update 
  const handleAverageChange = (groupTitle, average) => {
    setGroupAverages(prevAverages => ({
      ...prevAverages,
      [groupTitle]: average,
    }));
  };
 //manage the groups
  const groupedClauses = questions.reduce((groups, clause) => {
    const { groupTitle } = clause;
    if (!groups[groupTitle]) {
      groups[groupTitle] = [];
    }
    groups[groupTitle].push(clause);
    return groups;
  }, {});

  
  //Cohere predetermined Prompt 
  const handleOpenModal = async (content) => {
    try {
      setModalOpen(true);
      setIsTyping(true);
  
      // Call Cohere chat API instead of generate API
      const response = await axios.post(
        'https://api.cohere.ai/v1/chat',
        {
          query: `Explain this in non-technical terms: ${content}`,
          message_history: [], // No previous history since it's the first interaction for this modal
        },
        {
          headers: {
            Authorization: `Bearer BOgLrDeF6HF9kRfSlqFHUQsTAz0nomrhGd0PewI0`, // Replace with your Cohere API key
            'Content-Type': 'application/json',
          },
        }
      );
  
      const botResponse = response.data.text.trim();
      setMessages([{ sender: 'bot', text: botResponse }]); // Set the initial bot response in modal
      setIsTyping(false);
    } catch (error) {
      console.error('Error fetching response from Cohere AI:', error);
      setMessages([{ sender: 'bot', text: 'Error generating response. Please try again.' }]);
      setIsTyping(false);
    }
  };
  
/*If the user wants to continue the chat with the copliot this function is then called when the user 
submits their prompt*/

  const sendMessage = async (e) => {
    e.preventDefault();
    if (input.trim() === '') return;
  
    const userMessage = { sender: 'user', text: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput('');
    setIsTyping(true);
  
    try {
      const response = await axios.post(
        'https://api.cohere.ai/v1/chat',
        {
          query: input, // User's current input
          message_history: messages.map((msg) => ({
            user_name: msg.sender === 'user' ? 'User' : 'Bot',
            text: msg.text,
          })),
        },
        {
          headers: {
            Authorization: `Bearer BOgLrDeF6HF9kRfSlqFHUQsTAz0nomrhGd0PewI0`, // Replace with your API key
            'Content-Type': 'application/json',
          },
        }
      );
  
      const botResponse = response.data.text.trim();
      const botMessage = { sender: 'bot', text: botResponse };
      setMessages((prevMessages) => [...prevMessages, botMessage]); // Append the bot's reply
      setIsTyping(false);
    } catch (error) {
      console.error('Error generating response:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: 'Error generating response. Please try again.' },
      ]);
      setIsTyping(false);
    }
  };

  //handles the users prompt 
  const handleInputChange = (e) => {
    setInput(e.target.value);
  };
  //If the user wants to closr the Copilot 
  const handleCloseModal = () => {
    setModalOpen(false);
    setMessages([]);
  };

  const getUserId = () => {
    const user = auth.currentUser;
    return user.uid;
  };
  /* Checks if all the questions have been answered for the first time the user will be redirected to the dashboard then after that they wont 
  be and they can change their answers and they will be saved*/

  const checkAllQuestionsAnswered = async () => {
    const groupedClausesKeys = Object.keys(groupedClauses);
    const userId = getUserId(); // Get current user ID
  
    // Query the users collection to find the document for the current user
    const q = query(collection(db, 'users'), where('userId', '==', userId));
  
    try {
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        // We assume that each user has only one document, so we take the first result
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();
  
        // If DashReroute is already true, do nothing
        if (userData.DashReroute === true) {
          console.log('Dashboard is already enabled, skipping rerouting.');
          return false;
        }
  
        // Check if all questions are answered or marked as Not Applicable
        for (const groupTitle of groupedClausesKeys) {
          const clauses = groupedClauses[groupTitle];
  
          for (const clause of clauses) {
            const clauseAndSummaryText = `${clause.clause} - ${clause.text.split(' ').slice(0, 6).join(' ')}...`;
            const savedAnswer = answers[groupTitle]?.[clauseAndSummaryText];
  
            // Check if the question has a valid answer or is marked as Not Applicable
            const isNotApplicable = savedAnswer?.notApplicableSelected === true;
            const isAnswered = savedAnswer && (savedAnswer.percentage !== null || isNotApplicable);
  
            if (!isAnswered) {
              // If any question is neither answered nor marked as Not Applicable, return false
              return false;
            }
          }
        }
  
        // If all questions are either answered or marked as Not Applicable, update isAssessmentComplete and DashReroute
        try {
          await updateDoc(userDoc.ref, {
            isAssessmentComplete: true,  // Mark the assessment as complete
            DashReroute: true            // Set DashReroute to true to prevent future reroutes
          });
          console.log('User document updated with isAssessmentComplete: true and DashReroute: true.');
  
          // Optionally redirect to dashboard
          setTimeout(() => {
            window.location.href = '/Dashboard';  // Reroute to the dashboard
          }, 500);
  
        } catch (error) {
          console.error('Error updating user document:', error);
        }
  
        return true; // All questions answered or marked as Not Applicable
  
      } else {
        console.log('User document not found.');
        return false;
      }
    } catch (error) {
      console.error('Error querying user documents:', error);
      return false;
    }
  };

  //HTML for the frontend
  return (
    <div >
      <div className="AssessmentContent">
        {isSixTrue ? (
          <>
            <h2 className='titleText'>Top-down Cyber Risk Quantification</h2>
            <p className='sloganText'>Quantify, Mitigate, Secure: Unleash the Power of Data-Driven Cyber Risk Insights</p>
              {Object.entries(groupedClauses).map(([groupTitle, groupClauses], groupIndex) => (
                <CollapsibleTitle
                  key={groupIndex}
                  title={groupTitle} // e.g., group1, group2, group9
                  onAverageChange={handleAverageChange}
                >
                  {groupClauses.map(({ clause, text, SuggestArt, hasNotSureOption }, index) => {
                    // Extract the first 10 words from the text
                    const summaryText = text.split(' ').slice(0, 6).join(' ') + '...';
                    const clauseAndSummaryText = `${clause} - ${summaryText}`;
                    const savedData = answers[groupTitle]?.[clauseAndSummaryText] || { percentage: null, comment: '' };
                    console.log(`answers[groupTitle]?.[${clauseAndSummaryText}]`, savedData);

                    return (
                      <Collapsible
                        key={index}
                        title={clause}
                        summaryText={summaryText}  // Pass the summary text here
                        initialPercentage={0}
                        savedAnswers={savedData}
                        answers={answers}
                        setAnswers={setAnswers}
                        groupTitle={groupTitle}
                        notApplicable={hasNotSureOption}
                      >
                        <div className="AsessmentContainerBox">
                          <table className="assessment-table">
                            <thead>
                              <tr>
                                <th className='header-cell'>Description</th>
                                <th className='header-cell'>Suggest Artefacts</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="question-text-container">
                                <div className="question-text" onClick={() => handleOpenModal(text)} dangerouslySetInnerHTML={{ __html: text }}></div>
                                  <Modal
                                    isOpen={isModalOpen}
                                    onClose={handleCloseModal}
                                    messages={messages}
                                    isTyping={isTyping}
                                    input={input}
                                    handleInputChange={handleInputChange}
                                    sendMessage={sendMessage}
                                  />
                                </td>
                                <td className="information-box">{SuggestArt}</td>
                              </tr>
                            </tbody>
                          </table>   
                        </div>
                      </Collapsible>
                    );
                  })}
                </CollapsibleTitle>
              ))}
            </>
          ):( 
            <>
            <h1>Please Complete the Security Assessment</h1>
            </> 
          )}
          <p className='copyright'>© Copyright, 2024 Assentian. All Rights Reserved</p>
      </div>
     </div> 
  );
};

export default NewAssessment;


