import React from "react";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword} from "firebase/auth";
import { useState } from "react"
import { auth } from "../Firebase_Config/firebase";
import { Navigate } from "react-router-dom";
import { Alert } from '@mui/material';
import { Link } from "react-router-dom";
import { collection, addDoc,} from 'firebase/firestore';
import { db } from '../Firebase_Config/firebase';
import "./SignUp.css"; // Import the shared CSS file

const NewSignUp = () => {
  const initialFormState = {
    email: '',
    password: '',
    confirmPassword: '',
    OrganName: '',
    ACRA_Num: '',
    turnover: '',
    NumOfEmplyo: '',
    sector: '',
  };

  const [error, setError] = useState(null);
  const [formData, setFormData] = useState(initialFormState);
  const MAX_RETRY_ATTEMPTS = 3;

  const handleSignUp = async () => {
    // Validate form data
    if (!formData.email || !formData.password || !formData.confirmPassword ||
        !formData.OrganName || !formData.ACRA_Num || !formData.turnover ||
        !formData.NumOfEmplyo || !formData.sector) {
        setError("Please fill out all required fields.");
        return;
    }

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
        setError("Passwords do not match.");
        return;
    }
    let retryAttempts = 0;
    while (retryAttempts < MAX_RETRY_ATTEMPTS) {
        try {      
         
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;
            const sectorScore = calculateSectorScore(formData.sector);
            const employeeScore = calculateEmployeeScore(parseInt(formData.NumOfEmplyo ));
            
            // Add user data to Firestore
            const timestamp = new Date().toLocaleString();
            const userRef = collection(db, "users");
            const docRef = await addDoc(userRef, {
                userId: user.uid,
                email: formData.email,
                OrganName: formData.OrganName,
                ACRA_Num: formData.ACRA_Num,
                turnover: formData.turnover,
                NumOfEmplyo: formData.NumOfEmplyo,
                sector: formData.sector,
                Sector_Score: sectorScore,
                Employee_Score: employeeScore,
                isAdmin: false,
                isAssessmentComplete: false,
                isSixteenComplete: false,
                DashReroute: false,
                timestamp
            });
            
            if (docRef.id) {
                setFormData(initialFormState);
                console.log('User has been added successfully');
                window.location.href = '/SecurityAssessment';
            } else {
                retryAttempts++;
                console.log(`Retry attempt ${retryAttempts}`);
            }
               
        } catch (error) {
            // Handle errors
            console.error("Error signing up:", error);
            setError(error.message);
             return;
        }
    }
    setError("Failed to create account. Please contact the  Adminsitrator at info@assentian.com");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'turnover' || name === 'NumOfEmplyo') {
        // Check if value is a number
        if (isNaN(value)) {
            setError("Please enter a valid number for turnover and number of employees.");
            return;
        }
    }
    setError(null)
    setFormData({ ...formData, [name]: value });
  };
 
  

  const calculateSectorScore = (selectedSector) => {
    let sectorScore = 0;
    switch (selectedSector) {
        case "Manufacturing":
            sectorScore = 24.80;
            break;
        case "Finance and insurance":
            sectorScore = 18.90;
            break;
        case "Professional, business, and consumer services":
            sectorScore = 14.60;
            break;
       case "Energy":
            sectorScore = 10.70;
            break;
        case "Retail and wholesale":
            sectorScore = 8.70;
            break;
        case "Education":
            sectorScore = 7.30;
            break;
        case "Healthcare":
            sectorScore = 5.80;
            break;
        case "Government":
            sectorScore = 4.80;
            break;
        case "Transportation":
            sectorScore = 3.90;
            break;
        case "Media and telecom":
            sectorScore = 0.50;
            break;
        case "Other":
            sectorScore = 10.10;
            break;
        default:
            sectorScore = 0;
            break;
    }
    return sectorScore;
  };

  const calculateEmployeeScore = (numOfEmployees) => {
    if (numOfEmployees >= 1000) {
        return 70;
    } else {
        return 43; 
    }
  };

  return (
    <div style={{
      backgroundImage: `linear-gradient(195deg, rgba(66, 66, 74, 0.6), rgba(25, 25, 25, 0.6)), url('bg.jpeg')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      height: '100vh'
  }}>
      <div className="auth-containerUp">
          <div className="auth-boxUp">
              <div className="sign-in-headerUp">
                  <h2 className="Signh2Up">Sign Up</h2>
              </div>
              {error && <Alert severity="error">{error}</Alert>}
              <div>
                  <form>
                      <div className="form-row">
                          <input className="form_inputUp" type="text" id="OrganName" name="OrganName" value={formData.OrganName} onChange={handleInputChange} placeholder="Organisation Name" />
                          <input className="form_inputUp" type="text" id="ACRA_Num" name="ACRA_Num" value={formData.ACRA_Num} onChange={handleInputChange} placeholder="ACRA Number/Unique Entity Number (UEN)" />
                          <input className="form_inputUp" type="text" id="turnover" name="turnover" value={formData.turnover} onChange={handleInputChange} placeholder="Annual Turnover" />
                          <input className="form_inputUp" type="text" id="NumOfEmplyo" name="NumOfEmplyo" value={formData.NumOfEmplyo} onChange={handleInputChange} placeholder="Number of Employees" />
                      </div>
                      <div className="form-row">
                          <select className="form_inputUp" id="sector" name="sector" value={formData.sector} onChange={handleInputChange} placeholder="Select Sector">
                              <option value="">Select Sector</option>
                              <option value="Manufacturing">Manufacturing</option>
                              <option value="Finance and insurance">Finance and insurance</option>
                              <option value="Professional, business, and consumer services">Professional, business, and consumer services</option>
                              <option value="Retail and wholesale">Retail and wholesale</option>
                              <option value="Healthcare">Healthcare</option>
                              <option value="Government">Government</option>
                              <option value="Transportation">Transportation</option>
                              <option value="Media and telecom">Media and telecom</option>
                              <option value="Other">Other</option>
                          </select>
                          <input className="form_inputUp" type="text" id='email' name="email" value={formData.email} onChange={handleInputChange} placeholder="Email" />
                          <input className="form_inputUp" type="password" id='password' name="password" value={formData.password} onChange={handleInputChange} placeholder="Password" />
                          <input className="form_inputUp" type="password" id="confirmPassword" name="confirmPassword" value={formData.confirmPassword} onChange={handleInputChange} placeholder="Confirm Password" />
                      </div>
                      <button className="SignbuttonUp" type="button" onClick={handleSignUp}>Sign Up</button>
                  </form>
                  <p className="sign-up-textUp">
                      Already have an account? <a href="/SignIn">Sign In</a>
                  </p>
              </div>
          </div>
      </div>
      <p className='copyright sign'>© Copyright, 2024 Assentian. All Rights Reserved</p>
  </div>
  );
};

export default NewSignUp;
