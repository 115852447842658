import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

//Firebase configuration for things like Authentication, Database for user documents and answers then storage for any files uploaded.
const firebaseConfig = {
  apiKey: "AIzaSyC_3HM9syBzfs65AHf7wnXZZzO0Eqz99rY",
  authDomain: "singapore-assessment-v2-prod.firebaseapp.com",
  projectId: "singapore-assessment-v2-prod",
  storageBucket: "singapore-assessment-v2-prod.appspot.com",
  messagingSenderId: "258543940809",
  appId: "1:258543940809:web:49b740a2508b473663c918",
  measurementId: "G-4GTESZBJ0D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const st = getStorage(app);
export const addAnsToFirebase = async (data) => {
  try {
    // Add a new document with a generated ID to the "submissions" collection
    const docRef = await addDoc(collection(db, "submissions"), data);
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
    alert("Error adding document: ", e);
  }
};


export const auth = getAuth(app);
export { app, db, st };
