import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import './Threat.css';


const CveListComponent = ({ glossaryData }) => {
  const [cveDetails, setCveDetails] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [lockedList, setLockedList] = useState([]);
  const [selectedSeverities, setSelectedSeverities] = useState([]);
  const [lockedSeverity, setlockedSeverity] = useState([]);
  const [lockedImpact, setlockedImpact] =useState([]);
  const [selectedImpactScoreRanges, setSelectedImpactScoreRanges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [instruct, setinstruct] = useState(true);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  //List of keywords 
  const keywords = [ 
    'Microsoft',
    'Adobe',
    'Google',
    'Apple',
    'IBM',
    'Oracle',
    'AWS',
    'Meta',
    'SalesForce',
    'SAP',
  ];
  //List of Severties 
  const severities = [
    'HIGH', 
    'MEDIUM',
    'LOW'
  ];
  //Ranges for impact score
  const [impactScoreRanges, setImpactScoreRanges] = useState([
    { label: '10-8', range: { min: 8, max: 10 } },
    { label: '8-6', range: { min: 6, max: 8 } },
    { label: '6-4', range: { min: 4, max: 6 } },
    { label: '4-0', range: { min: 0, max: 4 } },
  ]);


  useEffect(() => {
    console.log("Selected Values:", selectedValues);
    console.log("Severity Value:", selectedSeverities);
    console.log("Impact Value:", selectedImpactScoreRanges)
    console.log("Locked List:", lockedList);
    console.log("Locked Severity:", lockedSeverity)
    console.log("Locked Impact:", lockedImpact)
    if (lockedList.length > 0 && lockedSeverity.length > 0  && lockedImpact.length > 0) {
      fetchCVEData();
    }
  }, [selectedValues, lockedList, selectedSeverities, lockedSeverity, selectedImpactScoreRanges, lockedImpact]);

  const proxyUrl = 'https://cyberrisk.api.assentian.com/'; // URL of the proxy server

  const fetchCVEData = async () => { // Function for fetching the CVE results
    setinstruct(false)
    setLoading(true);
    let i = 0;
    const requests = lockedList.map(value =>
      fetch(`${proxyUrl}cves?resultsPerPage=250&startIndex=0&keywordSearch=${value}`)
        .then(response => response.json())
    );

    Promise.all(requests)
      .then(responses => {
        let newCveDetails = [];

        responses.forEach(data => {
          const cveItems = data.vulnerabilities;
          // Assigns the results to their respective varibles
          cveItems.forEach(cveItem => {
            const cveIDs = cveItem.cve.id;
            const description = cveItem.cve.descriptions[0].value;

            let exploitScore;
            if (cveItem.cve.metrics.cvssMetricV2) {
              exploitScore = cveItem.cve.metrics.cvssMetricV2[0].exploitabilityScore;
            } else if (cveItem.cve.metrics.cvssMetricV3) {
              exploitScore = cveItem.cve.metrics.cvssMetricV3[0].exploitabilityScore;
            }

            let impScore;
            if (cveItem.cve.metrics.cvssMetricV2) {
              impScore = cveItem.cve.metrics.cvssMetricV2[0].impactScore;
            } else if (cveItem.cve.metrics.cvssMetricV3) {
              impScore = cveItem.cve.metrics.cvssMetricV3[0].impactScore;
            }

            let severity;
            if (cveItem.cve.metrics.cvssMetricV2) {
              severity = cveItem.cve.metrics.cvssMetricV2[0].baseSeverity;
            } else if (cveItem.cve.metrics.cvssMetricV3) {
              severity = cveItem.cve.metrics.cvssMetricV3[0].baseSeverity;
            }

            let productType = lockedList[i];
            //Pushes those results to a list 
            newCveDetails.push({
              CveID: cveIDs,
              Description: description,
              Severity: severity,
              Exploitability_Score: exploitScore,
              Impact_Score: impScore,
              Product_Type: productType,
            });
          });
          //Sorts the order of that list in the order of severity 
          newCveDetails.sort((a, b) => {
            const order = ['LOW', 'MEDIUM', 'HIGH', 'CRITICAL'];
            return order.indexOf(b.Severity) - order.indexOf(a.Severity);
          });

          i++;
        });
        //Sets the list again. 
        setCveDetails(newCveDetails);
        console.log(newCveDetails)
        setLoading(false);
      });
  };
  //Function for detecting which keywords are selected. 
  const toggleKeywords = (value) => {
    setSelectedValues((prevSelectedValues) => {
      if (prevSelectedValues.includes(value)) {
        return prevSelectedValues.filter(item => item !== value);
      } else {
        return [...prevSelectedValues, value];
      }
    });
  };

  const isSelected = (value) => {
    return selectedValues.includes(value);
  };
  //Function for detecting which Impacts are selected. 
  const toggleImpactScoreRange = (range) => {
    setSelectedImpactScoreRanges((prevSelectedRanges) => {
      if (prevSelectedRanges.includes(range)) {
        return prevSelectedRanges.filter((item) => item !== range);
      } else {
        return [...prevSelectedRanges, range];
      }
    });
  };
  const isImpactScoreRangeSelected = (range) => {
    return selectedImpactScoreRanges.includes(range);
  };

  //Function for detecting which Severity are selected.
  const toggleSeverity = (severity) => {
    setSelectedSeverities((prevSelectedSeverities) => {
      if (prevSelectedSeverities.includes(severity)) {
        return prevSelectedSeverities.filter(item => item !== severity);
      } else {
        return [...prevSelectedSeverities, severity];
      }
    });
  };

  const isSeveritySelected = (severity) => {
    return selectedSeverities.includes(severity);
  };
 //Filters the list by the selected impact score
  const filteredCveDetails = cveDetails.filter((cveDetail) => {
    return (
      (lockedImpact.length === 0 ||
        lockedImpact.some(
          (range) =>
            cveDetail.Impact_Score >= range.range.min && cveDetail.Impact_Score <= range.range.max
        )) &&
      (lockedSeverity.length === 0 || lockedSeverity.includes(cveDetail.Severity))
    );    
  });
  filteredCveDetails.sort((a, b) => b.Impact_Score - a.Impact_Score);

  console.log(filteredCveDetails)
  
  
  //Locks the users selections ready to be processed
  const lockList = () => {
    setLoading(true); 
    setLockedList((prevLockedList) => [...prevLockedList, ...selectedValues]);
    setlockedSeverity((prevlockedSeverity) => [...prevlockedSeverity, ...selectedSeverities])
    setlockedImpact((prevlockedImpact) => [...prevlockedImpact, ...selectedImpactScoreRanges])
    console.log('Clicked')
  };
 //Clears the selections and results 
  const clearList = () => {
    setLockedList([]);
    setlockedSeverity([]);
    setlockedImpact([]);
    setSelectedValues([]);
    setSelectedSeverities([]);
    setCveDetails([]);
    setSelectedImpactScoreRanges([])
    setinstruct(true)
  };
 // Handles when an identifed gloassary term is clickec
  const handleTermClick = (term) => {
    const glossaryDefinition = glossaryData.find((item) => item.term === term);

    const dialogTitle = `Glossary Term: ${term}`;
    const dialogContent = glossaryDefinition && glossaryDefinition.definitions && glossaryDefinition.definitions[0] ?
        glossaryDefinition.definitions[0].text :
        'Glossary definition not found or is incomplete';

    setTitle(dialogTitle)
    setContent(dialogContent)
    setOpen(true)
  };


  const glossaryIndex = {};
  glossaryData.forEach(item => {
  const lowercasedTerm = item.term.toLowerCase();
  if (!glossaryIndex[lowercasedTerm]) {
    glossaryIndex[lowercasedTerm] = item.term;
  }
});
//Function for matching glossary terms within the description 
const findMatchingGlossaryTerm = (words) => {
  const lowerCaseWords = words.map(word => word.toLowerCase());
  let matchingTerm = null;

  for (let i = words.length; i > 0; i--) {
    const partialWords = lowerCaseWords.slice(0, i);
    const combinedWord = partialWords.join(' ');

    if (glossaryIndex[combinedWord]) {
      matchingTerm = glossaryIndex[combinedWord]; 
    }
  }
  return matchingTerm;
};


  

  
  
  
// Splits the description up to allow it to be clikcable
const renderClickableDescription = (description) => {
  const words = description.split(' ');

  return words.map((word, index) => {
    let matchingTerm = null;

    for (let i = 1; i <= 3; i++) {
      const nextWords = words.slice(index, index + i);
      const foundTerm = findMatchingGlossaryTerm(nextWords);

      if (foundTerm) {
        matchingTerm = foundTerm;
        break;
      }
    }

    if (matchingTerm) {
      const glossaryDefinition = glossaryData.find((item) => item.term === matchingTerm);

      if (glossaryDefinition && glossaryDefinition.definitions && glossaryDefinition.definitions[0]) {
        return (
          <span key={index}>
            {index > 0 && ' '}
            <span onClick={() => handleTermClick(matchingTerm)} className="highlight">
              {word}
            </span>
          </span>
        );
      }
    }

    return <span key={index}>{index > 0 && ' ' }{word}</span>;
  });
};




  //HTML for the frontend 
  return (
    <div style={{
      backgroundColor: 'rgb(240, 242, 245)', 
      minHeight: '100vh', 
      width: '100%', 
      margin: 0, 
      padding: 0
    }}>
      <div className='Intelcontent'>
        <div className="outerContainer">
          <div className="Threatheader">
            <h1>Threat Intelligence</h1>
          </div>
          <div className="Threatcontent">
            <div className="input-container">
              <div className="section-container">
            
                <div className="section-box keyword-section">
                  <h2 className="fontBigger">Keywords:</h2>
                  <div className="keyword-checkboxes">
                  <div className="keyword-column">
                    {keywords.slice(0, 5).map((keyword, index) => (
                      <div className="fontBigger" key={index}>
                        <label>
                          <input
                            type="checkbox"
                            value={keyword}
                            onChange={() => toggleKeywords(keyword)}
                            checked={isSelected(keyword)}
                          />
                          <span className="custom-checkbox"></span>
                          {keyword}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="keyword-column">
                    {keywords.slice(5).map((keyword, index) => (
                      <div className="fontBigger" key={index}>
                        <label>
                          <input
                            type="checkbox"
                            value={keyword}
                            onChange={() => toggleKeywords(keyword)}
                            checked={isSelected(keyword)}
                          />
                          <span className="custom-checkbox"></span>
                          {keyword}
                        </label>
                      </div>
                      ))}
                    </div>
                  </div>
                </div>

              
                <div className="section-box severity-section">
                  <h2 className="fontBigger">Severity Levels:</h2>
                  <div className="severity-checkboxes">
                    {severities.map((severity, index) => (
                      <div className="fontBigger" key={index}>
                        <label>
                          <input
                            type="checkbox"
                            value={severity}
                            onChange={() => toggleSeverity(severity)}
                            checked={isSeveritySelected(severity)}
                          />
                          <span className="custom-checkbox"></span>
                          {severity}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

            
                <div className="section-box keyword-section">
                  <h2 className="fontBigger">Impact Score Ranges:</h2>
                  <div className="keyword-checkboxes">
                    <div className="keyword-column">
                      {impactScoreRanges.slice(0, 2).map((range, index) => (
                        <div className="fontBigger" key={index}>
                          <label>
                            <input
                              type="checkbox"
                              value={range.label}
                              onChange={() => toggleImpactScoreRange(range)}
                              checked={isImpactScoreRangeSelected(range)}
                            />
                            <span className="custom-checkbox"></span>
                            {range.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="keyword-column">
                      {impactScoreRanges.slice(2).map((range, index) => (
                        <div className="fontBigger" key={index}>
                          <label>
                            <input
                              type="checkbox"
                              value={range.label}
                              onChange={() => toggleImpactScoreRange(range)}
                              checked={isImpactScoreRangeSelected(range)}
                            />
                            <span className="custom-checkbox"></span>
                            {range.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

        
              <div className="button-section">
                <button className="cve-dropdown" onClick={lockList}>
                  <b>Submit</b>
                </button>
                <button className="cve-dropdown" onClick={clearList}>
                  <b>Clear</b>
                </button>
              </div>
            </div>

            <div className="cve-info-container">
              <h2 className="cveTitle">CVE Information</h2>
              {loading && (
                <div>
                  <div>
                    <label className="fontBigger label1">
                      Select product keywords, then click "Submit." If you would like to try again, click "Clear" and reselect. This will also clear current results:
                    </label>
                  </div>
                  <div className="loading-spinner"></div>
                </div>
              )}
              <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                  <DialogContentText>{content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <button className="buttonClose" onClick={() => setOpen(false)}>Close</button>
                </DialogActions>
              </Dialog>

              {/* Conditional message if no results are found */}
              {instruct && (
                <label className="fontBigger label1">
                  Select product keywords, then click "Submit." If you would like to try again, click "Clear" and reselect. This will also clear current results:
                </label>
              )}

              {!loading && !instruct && filteredCveDetails.length === 0 && (
                <div className="no-threats-message">
                  <p>No threats found based on your selection. Please click "Clear" and reselect. This will also clear current results: </p>
                </div>
              )}

              {filteredCveDetails.length > 0 && filteredCveDetails.map((cveDetail, index) => (
                <div key={index}>
                  <table className="cve-table">
                    <tbody>
                      <tr>
                        <td className="cve-label">CVE ID:</td>
                        <td className="cve-data">{cveDetail.CveID}</td>
                      </tr>
                      <tr>
                        <td className="cve-label">Description:</td>
                        <td className="cve-data">{renderClickableDescription(cveDetail.Description)}</td>
                      </tr>
                      <tr>
                        <td className="cve-label">Severity:</td>
                        <td className="cve-data">{cveDetail.Severity}</td>
                      </tr>
                      <tr>
                        <td className="cve-label">Exploitability Score:</td>
                        <td className="cve-data">{cveDetail.Exploitability_Score}</td>
                      </tr>
                      <tr>
                        <td className="cve-label">Impact Score:</td>
                        <td className="cve-data">{cveDetail.Impact_Score}</td>
                      </tr>
                      <tr>
                        <td className="cve-label">Product Type:</td>
                        <td className="cve-data">{cveDetail.Product_Type}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="cve-separator"></div>
                </div>
              ))}
            </div>
          </div>
          <p className='copyright'>© Copyright, 2024 Assentian. All Rights Reserved</p>
        </div>
      </div>
    </div>




    
  );
};

export default CveListComponent;
